import React, { lazy, Component, Suspense } from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import { observer } from "mobx-react";
import { ToastProvider } from "react-toast-notifications";
import { ThemeProvider } from "react-jss";
import { Themes, Loader, Text, Panel, Field } from "ks_storybook";
import AppStore from "./stores/AppStore";
import AppContext from "./AppContext";
import Select from "./Components/Select/Select.jsx";
import es_ES from "./lang/es_ES.js";
import en_EN from "./lang/en_EN.js";
import fr_FR from "./lang/fr_FR.js";
//import  PasswordRecoveryRouter from './views/PasswordRecoveryRouter/PasswordRecoveryRouter'

//const PublicRouter = lazy(() => import('./views/PublicRouter/PublicRouter'));

import AppRouter from "./views/AppRouter/AppRouter";
import MobileAppRouter from "./views/AppRouter/MobileAppRouter";
import SessionRouter from "./views/SessionRouter/SessionRouter";

import MediaQuery from "react-responsive";
import injectSheet from "react-jss";

import styles from "./styles/styles.css";
import GlobalStyle from "./styles/globalStyle.js";

@observer
class App extends Component {
  constructor(props) {
    super(props);

    this.appStore = new AppStore();
    this.state = {};
  }

  renderValue = (value) => {
    return value >= 0 ? this.renderFrame() : this.renderFrame();
  };

  renderFrame() {
    return (
      <React.Fragment>
        <MediaQuery minDeviceWidth={1025}>
          {(desk) => {
            return (
              <BrowserRouter>
                <Switch>
                  <Route path={"/session"} component={SessionRouter} />
                  {/* <Route  path = {'/reset_password' } component={ PasswordRecoveryRouter } /> */}
                  {/*<Route path={'/public'} component={PublicRouter} />*/}
                  {(this.appStore.viewMobile = desk)}
                  <Route
                    path={"/app"}
                    component={desk ? AppRouter : MobileAppRouter}
                  />
                  {/* Replace this by a 404... maybe.... */}
                  <Redirect
                    to={"/app"}
                    component={desk ? AppRouter : MobileAppRouter}
                  />
                </Switch>
              </BrowserRouter>
            );
          }}
        </MediaQuery>
        {this.appStore.serviceErrors.length > 0
          ? this.renderServiceErrors()
          : null}
      </React.Fragment>
    );
  }

  renderServiceErrors() {
    return (
      <Panel
        color="error"
        style={{ position: "fixed", bottom: "8px", left: "8px", right: "8px" }}
      >
        {this.appStore.serviceErrors.map((error) => (
          <Text>
            {error.message}
            <Text
              size="xs"
              color="white"
            >{`${error.service} - ${error.detail}`}</Text>
          </Text>
        ))}
      </Panel>
    );
  }

  render() {
    const loader = (
      <Loader
        className="fullscreen"
        icon="sync-alt"
        label={this.appStore.language.label5}
        animation="spin"
      />
    );
    return (
      <AppContext.Provider value={this.appStore}>
        <GlobalStyle />
        <ThemeProvider theme={Themes[this.appStore.getCurrentTheme()]}>
          <ToastProvider placement="bottom-right" autoDismissTimeout={6000}>
            <React.Fragment>
              <Suspense fallback={loader}>
                {this.appStore.isLoading || this.appStore.isLoggingIn
                  ? loader
                  : this.renderValue(this.appStore.lang)}
              </Suspense>
            </React.Fragment>
          </ToastProvider>
        </ThemeProvider>
      </AppContext.Provider>
    );
  }
}

export default injectSheet(styles)(App);
