import React, { Component } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { withRouter } from "react-router";
import { observer } from "mobx-react";
import withStore from "../../hocs/withStore";
import {
  Layout,
  LayoutNavbar,
  LayoutNavbarStart,
  LayoutNavbarEnd,
  LayoutSide,
  LayoutContent,
  Text,
  Sidebar,
} from "ks_storybook";
import { SignIn } from "../Session";
import { UsersManagerRouter, UsersManagerMenu } from "../UsersManager";
import LoggedUserDropdown from "./components/LoggedUserDropdown";

// remove this later, when all routes exist -----------
//const FakeContent = withRouter(props => <div>{props.location.pathname}</div>);

@observer
class AppRouter extends Component {
  render() {
    const path = this.props.match.path;
    const root = "/usersmanager/";

    if (this.props.store.isLoggedIn || this.props.store.isLoggingIn) {
      const uiSettings = this.props.store.ui.settings;

      if (uiSettings.isEmpty()) return null;

      const user = this.props.store.loggedInUser;

      return (
        <React.Fragment>
          <Layout
            showSide={this.props.store.ui.showMainSide}
            noNavbar
            style={{ background: "#121212" }}
          >
            {/* <LayoutSide style={{ width: "auto" }}>
              <Switch>
                <Route path={`${path}${root}`} component={UsersManagerMenu} />
              </Switch>
            </LayoutSide> */}
            <Sidebar>
              <Switch>
                <Route path={`${path}${root}`} component={UsersManagerMenu} />
              </Switch>
            </Sidebar>
            <LayoutContent>
              <LayoutNavbar>
                <LayoutNavbarStart fillWidth justifyContent />
                <LayoutNavbarEnd fillWidth>
                  <LoggedUserDropdown />
                </LayoutNavbarEnd>
              </LayoutNavbar>
              <Switch>
                <Route
                  exact
                  path={`${path}${root}`}
                  component={UsersManagerRouter}
                />
                <UsersManagerRouter defaultComponent={UsersManagerRouter} />
                <Redirect
                  to={`${path}${root}`}
                  component={UsersManagerRouter}
                />
              </Switch>
            </LayoutContent>
          </Layout>
        </React.Fragment>
      );
    }

    return (
      <Switch>
        <Redirect to={`/session/signIn`} component={SignIn} />
      </Switch>
    );
  }
}

export default withStore(withRouter(AppRouter));
