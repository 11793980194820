import React, { Component } from "react";
import { observer, PropTypes } from "mobx-react";
import withStore from "../../hocs/withStore";
import {
  Field,
  TextInput,
  Modal,
  Button,
  ModalHeader,
  ModalContent,
  ModalFooter,
  Text,
} from "ks_storybook";
import { withToastManager } from "react-toast-notifications";

@observer
class LicensesModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isSaving: false,
    };
    this.params = this.newParams();
  }

  newParams = () => {
    const params = {
      user_id: this.props.account.id,
      num_licenses: null,
    };
    return params;
  };

  handleChangeFiled = (sender, value, name, validation) => {
    this.params[name] = value;
    this.setState({ isSaving: false });
  };

  handleClose = () => {
    this.props.onClose && this.props.onClose();
  };

  getErrorMessager(codError, type) {
    const messager = {
      1:
        type === 1
          ? "Error, Usuario no encontrado."
          : "Error, Usuario sin licencias",
      2:
        type === 1
          ? "Error, no se puedo añadir las licencias."
          : "Error, El número de licencias para eliminar es mayor que el número de licencias disponibles.",
      3: "Error, no se puedo eliminar las licencias.",
    };
    return messager[codError];
  }

  handleSave = () => {
    const { toastManager, type } = this.props;
    this.setState({ isSaving: true }, () => {
      if (type === 1) {
        this.props.store.kasimuAPIClient
          .putOne(
            "/users/put_add_licenses",
            "?user_id=" +
              this.params.user_id +
              "&num_licenses=" +
              this.params.num_licenses
          )
          .then(
            (res) => {
              toastManager.add("Se agregaron las licencias exitosamente.", {
                appearance: "success",
                autoDismiss: true,
              });
              this.props.onOk && this.props.onOk();
            },
            (error) => {
              let str = error.toString();
              str = str.replace("Error: ", "");
              let mydatas = JSON.parse(str);
              this.setState({
                messageError: this.getErrorMessager(
                  mydatas["codeResponse"],
                  type
                ),
              });
              this.setState({ isSaving: false });
            }
          )
          .catch((error) => {
            toastManager.add("Error, algo salio mal al agregar licencias.", {
              appearance: "error",
              autoDismiss: true,
            });
          });
      } else {
        this.props.store.kasimuAPIClient
          .putOne(
            "/users/put_deleted_licenses",
            "?user_id=" +
              this.params.user_id +
              "&num_licenses=" +
              this.params.num_licenses
          )
          .then(
            (res) => {
              toastManager.add("Se eliminaron las licencias exitosamente.", {
                appearance: "success",
                autoDismiss: true,
              });
              this.props.onOk && this.props.onOk();
            },
            (error) => {
              let str = error.toString();
              str = str.replace("Error: ", "");
              let mydatas = JSON.parse(str);
              this.setState({
                messageError: this.getErrorMessager(
                  mydatas["codeResponse"],
                  type
                ),
              });
              this.setState({ isSaving: false });
            }
          )
          .catch((error) => {
            toastManager.add(
              "Error, algo salio mal al eliminar las licencias.",
              {
                appearance: "error",
                autoDismiss: true,
              }
            );
          });
      }
    });
  };

  renderButtons = () => {
    return (
      <nav class="level is-mobile">
        <div className="level-left" />
        <div className="level-right mb-2">
          {this.state.isSaving ? (
            <Button
              className="is-pulled-right px-3"
              size={this.props.store.viewMobile ? "lg" : "md"}
              disabled
              icon="spinner"
              pulse
            >
              {this.props.type === 1 ? "Agregando..." : "Eliminando..."}
            </Button>
          ) : (
            <Button
              // icon={this.props.type === 1 ? "save" : "trash"}
              size={this.props.store.viewMobile ? "lg" : "md"}
              disabled={!this.params.num_licenses}
              className="is-pulled-right px-3"
              onClick={this.handleSave}
            >
              <strong>{this.props.type === 1 ? "Agregar" : "Eliminar"}</strong>
            </Button>
          )}
        </div>
      </nav>
    );
  };

  render() {
    const { title } = this.props;
    return (
      <Modal
        show
        onClose={this.handleClose}
        width={this.props.store.viewMobile ? "400px" : "90%"}
      >
        <ModalHeader>
          <Text
            lineBreak
            size={this.props.store.viewMobile ? "md" : "sm"}
            lead
            weight="black"
          >
            {title}
          </Text>
        </ModalHeader>
        <ModalContent>
          <div className="my-1">
            <Field
              marginH={this.props.store.viewMobile && "4px"}
              label={"Numero de licencias"}
              size={this.props.store.viewMobile ? "xl" : "lg"}
              noteSize={this.props.store.viewMobile ? "sm" : "xs"}
              weight="medium"
            >
              <TextInput
                backgroundColor="blackDark"
                borderColor="black"
                className="is-fullwidth my-1"
                size={this.props.store.viewMobile ? "xl" : "lg"}
                paddingInput={"20px"}
                type="number"
                name="num_licenses"
                onChange={this.handleChangeFiled}
                minlength={1}
                maxlength={10}
                value={this.params.num_licenses}
                autocomplete="nope"
              />
            </Field>
          </div>
        </ModalContent>
        <ModalFooter>{this.renderButtons()}</ModalFooter>
      </Modal>
    );
  }
}

LicensesModal.propTypes = {
  type: PropTypes.number,
  title: PropTypes.string,
  onClose: PropTypes.func,
  onOk: PropTypes.func,
};

LicensesModal.defaultProps = {
  type: null,
  title: null,
  onClose: null,
  onOk: null,
};

export default withToastManager(withStore(LicensesModal));
