import React, { Component } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { withRouter } from "react-router";
import AccountManagerPage from './Accounts/AccountManagerPage';
import withStore from "../../hocs/withStore";

class UsersManagerRouter extends Component {
  componentDidMount() {
    this.props.store.ui.showMainSide = true;
  }

  render() {
    const path = this.props.match.path;
    return (
      <div className='ks--container'>
        <Switch>
            <Route
                exact
                path={`${path}/usersmanager/accountmanagerpage`}
                component={AccountManagerPage}
            />,
            <Redirect
                to={`${path}/usersmanager/accountmanagerpage`}
                component={AccountManagerPage}
            />          
        </Switch>
      </div>
    );
  }
}

export default withRouter(withStore(UsersManagerRouter));
