import { Store } from "ks_storybook";
import { SongStyles } from "../models";

class SongStylesStore extends Store {
  model = SongStyles;

  fetchAll() {
    return this.search(
      { per_page: 1000 },
      "song_styles_all",
      true,
      "/song_styles"
    );
  }

  fetchSearch(per_page, search) {
    return this.search(
      { per_page: per_page, search: search },
      "song_styles_search",
      true,
      "/song_styles/get_search"
    );
  }

  fetchAllParams(per_page, filters, orders, search) {
    return this.search(
      {
        per_page: per_page,
        filters: JSON.stringify(filters),
        orders: JSON.stringify(orders),
        search: search,
      },
      "song_styles_get_all_params",
      true,
      "/song_styles/get_all_params"
    );
  }

  fetchPage(per_page, page, filters, orders, search) {
    return this.search(
      {
        per_page: per_page,
        page: page,
        filters: JSON.stringify(filters),
        orders: JSON.stringify(orders),
        search: search,
      },
      "song_styles_get_all_params",
      true,
      "/song_styles/get_all_params"
    );
  }

  fetchSearchFreeRights(per_page, free_rights) {
    return this.search(
      { per_page: per_page, free_rights: free_rights },
      "song_styles_search_get_search_styles_free_rights",
      true,
      "/song_styles/get_search_styles_free_rights"
    );
  }
}

export default SongStylesStore;
