import { Store } from "ks_storybook";
import { ReportsSongStyles } from "../models";

class ReportsSongStylesStore extends Store {
  model = ReportsSongStyles;

  fetchSearch(per_page, song_styles_id) {
    return this.search(
      { per_page: per_page, song_styles_id: song_styles_id },
      "reports_song_styles_search",
      true,
      "/reports_song_styles/get_search"
    );
  }

  movePage(per_page, page, song_styles_id) {
    return this.search(
      { per_page: per_page, page: page, song_styles_id: song_styles_id },
      "reports_song_styles_search_page",
      true,
      "/reports_song_styles/get_search"
    );
  }
}

export default ReportsSongStylesStore;
