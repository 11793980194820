import React, { Component } from "react";
import { observer } from "mobx-react";
import withStore from "../../../hocs/withStore";
import { withToastManager } from "react-toast-notifications";
import {
  Text,
  TextInput,
  Button,
  Panel,
  Loader,
  Field,
  Columns,
  Column,
} from "ks_storybook";
import {
  EmptyContent,
  Pagination,
  ConfirmationModal,
} from "../../../Components/Common";
import {
  InformationModal,
  LicensesModal,
  InvoicesAMemberModal,
  EditRolModal,
} from "../../../Components/Accounts";
import { Screen } from "../../../Components/Common";
import moment from "moment";
import ReactTooltip from "react-tooltip";

@observer
class AccountManagerPage extends Component {
  constructor(props) {
    super(props);

    this.per_page = 25;
    this.state = {
      currentAccount: null,
      currentType: null,
      accounts: this.getAccountsParams(this.per_page, null, null, null),
      showInformationModal: false,
      showInvoicesAMemberModal: false,
      showLicensesModal: false,
      showEditRolModal: false,
      showConfirmationStatusModal: false,
      page: 1,
      filters: null,
      orders: null,
      orderList: [],
      screen: {
        actions: false,
      },
      titleScreen: "",
    };

    this.search = null;
  }

  componentDidMount() {
    this.props.store.menuTitle = "Administrador de usuarios";
  }

  getAccountsParams = (per_page, filters, orders, search) => {
    return this.props.store.users.fetchAllParams(
      per_page,
      filters,
      orders,
      search
    );
  };

  onPageChange = (page) => {
    const { filters, orderList } = this.state;
    this.setState({
      accounts: this.props.store.users.fetchPage(
        this.per_page,
        page,
        filters ? filters : null,
        orderList.length == 0 ? null : orderList,
        this.search
      ),
      page: page,
    });
  };

  reload = () => {
    const { filters, orderList, page } = this.state;
    this.setState({
      showLicensesModal: false,
      showEditRolModal: false,
      accounts: this.props.store.users.fetchPage(
        this.per_page,
        page,
        filters ? filters : null,
        orderList.length == 0 ? null : orderList,
        this.search
      ),
      page: page,
    });
  };

  handleKeyPress = (event) => {
    if (event.key == "Enter") {
      this.onSearch();
    }
  };

  onSearch = () => {
    const { filters, orderList } = this.state;
    if (this.search && this.search != " ") {
      this.setState({
        accounts: this.props.store.users.fetchAllParams(
          this.per_page,
          filters ? filters : null,
          orderList.length == 0 ? null : orderList,
          this.search
        ),
        page: 1,
      });
    }
  };

  handleChangeSearch = (sender, value, name) => {
    this.search = value;
  };

  handleInformationModalOpen = (account) => {
    this.setState({ showInformationModal: true, currentAccount: account });
  };

  handleInformationModalClose = () => {
    this.setState({ showInformationModal: false, currentAccount: null });
  };

  handleLicensesModalOpen = (account, type) => {
    this.setState({
      showLicensesModal: true,
      currentAccount: account,
      currentType: type,
    });
  };

  handleLicensesModalClose = () => {
    this.setState({
      showLicensesModal: false,
      currentAccount: null,
      currentType: null,
    });
  };

  handleEditRolModalOpen = (account) => {
    this.setState({ showEditRolModal: true, currentAccount: account });
  };

  handleEditRolModalClose = () => {
    this.setState({ showEditRolModal: false, currentAccount: null });
  };

  handleInvoicesAMemberModalOpen = (account) => {
    this.setState({ showInvoicesAMemberModal: true, currentAccount: account });
  };

  handleInvoicesAMemberModalClose = () => {
    this.setState({ showInvoicesAMemberModal: false, currentAccount: null });
  };

  handleConfirmationStatusModalOpen = (account) => {
    this.setState({
      showConfirmationStatusModal: true,
      currentAccount: account,
    });
  };

  handleConfirmationStatusModalClose = () => {
    this.setState({ showConfirmationStatusModal: false, currentAccount: null });
  };

  updateStatus = (status) => {
    const { filters, orderList, page, currentAccount } = this.state;
    const { toastManager } = this.props;
    this.setState(
      {
        showConfirmationStatusModal: false,
        showInformationModal: false,
      },
      () => {
        this.props.store.kasimuAPIClient
          .putOne(
            "/users/put_status_user",
            "?user_id=" + currentAccount.id + "&status=" + status
          )
          .then(
            (res) => {
              toastManager.add(
                status == 1
                  ? "Se activado exitosamente al usuario " +
                      currentAccount.username
                  : "Se suspendido exitosamente al usuario " +
                      currentAccount.username,
                {
                  appearance: "success",
                  autoDismiss: true,
                }
              );
              this.setState({
                currentAccount: null,
                accounts: this.props.store.users.fetchPage(
                  this.per_page,
                  page,
                  filters ? filters : null,
                  orderList.length == 0 ? null : orderList,
                  this.search
                ),
                page: page,
              });
            },
            (error) => {
              toastManager.add(
                status == 1
                  ? "Error, algo salio mal al activar el usuario " +
                      currentAccount.username
                  : "Error, algo salio mal al suspender el usuario " +
                      currentAccount.username,
                {
                  appearance: "error",
                  autoDismiss: true,
                }
              );
              this.setState({ currentAccount: null });
            }
          )
          .catch((error) => {
            toastManager.add(
              status == 1
                ? "Error, algo salio mal al activar el usuario " +
                    currentAccount.username
                : "Error, algo salio mal al suspender el usuario " +
                    currentAccount.username,
              {
                appearance: "error",
                autoDismiss: true,
              }
            );
            this.setState({ currentAccount: null });
          });
      }
    );
  };

  getPanel = (result) => {
    return (
      <Columns
        className={this.props.store.viewMobile && "my-2"}
        style={{
          margin: !this.props.store.viewMobile && "6px 0",
        }}
        isMultiline
      >
        <Column
          isSize={{ mobile: 12, tablet: 12, desktop: 12 }}
          className="flex"
        >
          <div
            className={this.props.store.viewMobile && "mr-2"}
            style={{
              maxWidth: "700px",
              width: "100%",
              marginRight: !this.props.store.viewMobile && "6px",
            }}
          >
            <TextInput
              className="is-fullwidth"
              size={this.props.store.viewMobile ? "xl" : "lg"}
              iconKasimu="search"
              backgroundColor="grayDark"
              colorIcon="gray"
              paddingInput={this.props.store.viewMobile ? "20px" : "16px"}
              invert
              onKeyPress={this.handleKeyPress}
              onChange={this.handleChangeSearch}
              placeholder={
                "Escribe aquí el nombre del administrador o usuario a buscar..."
              }
              value={this.search && this.search}
            />
          </div>
          <Button
            kind="link"
            color="basic"
            size={this.props.store.viewMobile ? "xl" : "xl"}
            iconKasimu="refresh"
            onClick={this.reload}
            space
          />
        </Column>
      </Columns>
    );
  };

  renderEmptyContent = () => {
    return (
      <React.Fragment>
        <br />
        <EmptyContent />
      </React.Fragment>
    );
  };

  renderLoader = () => {
    return (
      <Loader
        icon="sync-alt"
        label={this.props.store.language.label42}
        animation="spin"
        backgroundColor="transparent"
      />
    );
  };

  renderPaginator = (accounts) => {
    if (accounts.totalPages == 1) return null;
    return (
      <React.Fragment>
        <div className={this.props.store.viewMobile ? "my-4" : "my-3"}>
          <Pagination
            totalPages={accounts.totalPages}
            currentPage={accounts.currentPage}
            onPageChangeClick={this.onPageChange}
          />
        </div>
      </React.Fragment>
    );
  };

  getIconStatus = (status) => {
    const array = { 1: "user-check", 2: "user-slash" };
    return array[status];
  };

  getColorStatus = (status) => {
    const colors = { 1: "statusPublished", 2: "statusModified" };
    return colors[status];
  };

  renderItemsRow(account) {
    const user = this.props.store.loggedInUser;
    return (
      <Panel
        color={"gray"}
        padding={this.props.store.viewMobile ? "16px 30px" : "14px 10px"}
        style={{ borderRadius: !this.props.store.viewMobile && 5 }}
      >
        <Columns isMobile isVCentered isMarginless isGapless>
          <Column isSize={{ mobile: 12, desktop: 12, tablet: 12 }}>
            <div
              className="flex items-center"
              style={{
                justifyContent: "space-between",
              }}
            >
              <div className="mr-1">
                <Text
                  color="white"
                  size={this.props.store.viewMobile ? "md" : "sm"}
                  lead
                  weight="medium"
                  lineBreak
                >
                  <span>
                    <strong className="has-text-light">
                      {account.username}
                    </strong>
                  </span>
                </Text>

                <div
                  className="is-row is-fullwidth"
                  style={{
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Text
                    color="whiteLight"
                    size={this.props.store.viewMobile ? "xl" : "lg"}
                    weight="light"
                    multiline
                  >
                    {account.ks_rol
                      ? "Rol: " + account.ks_rol.ks_rol.name
                      : "Rol: Sin rol"}
                    {account.suscription
                      ? " | Servicio: " +
                      (account.suscription.amember_categories?account.suscription.amember_categories.name:'Sin suscripción en amember')
                      : null}
                    {account.licenses
                      ? " | Licencias contratadas: " +
                        account.licenses.count_licenses +
                        " | Licencias en uso: " +
                        account.licenses.count_users +
                        " | Licencias disponibles: " +
                        account.licenses.licenses_available
                      : null}
                    {account.franquisor_username
                      ? " | Usuario administrador: " +
                        account.franquisor_username.username
                      : null}
                  </Text>
                </div>
              </div>
              {this.props.store.viewMobile ? (
                <div className="flex">
                  <div data-tip data-for="info">
                    <Button
                      icon="search-plus"
                      kind="link"
                      color="basic"
                      size="lg"
                      lead
                      space
                      onClick={() => {
                        this.handleInformationModalOpen(account);
                      }}
                    />
                  </div>
                  {user.getRolId() == 1 || user.getRolId() == 2 ? (
                    account.ks_rol ? (
                      account.ks_rol.ks_rol_id == 5 ||
                      account.ks_rol.ks_rol_id == 8 ? (
                        <div data-tip data-for="invoices">
                          <Button
                            className="ml-3"
                            icon="file-invoice-dollar"
                            kind="link"
                            color="basic"
                            size="lg"
                            lead
                            space
                            onClick={() => {
                              this.handleInvoicesAMemberModalOpen(account);
                            }}
                          />
                        </div>
                      ) : null
                    ) : null
                  ) : null}
                  {user.getRolId() == 1 || user.getRolId() == 2 ? (
                    account.ks_rol ? (
                      account.ks_rol.ks_rol_id == 5 ? (
                        <div data-tip data-for="lp">
                          <Button
                            className="ml-3"
                            icon="user-plus"
                            kind="link"
                            color="basic"
                            size="lg"
                            lead
                            space
                            onClick={() => {
                              this.handleLicensesModalOpen(account, 1);
                            }}
                          />
                        </div>
                      ) : null
                    ) : null
                  ) : null}
                  {user.getRolId() == 1 || user.getRolId() == 2 ? (
                    account.ks_rol ? (
                      account.ks_rol.ks_rol_id == 5 ? (
                        <div data-tip data-for="lr">
                          <Button
                            className="ml-3"
                            icon="user-minus"
                            kind="link"
                            color="basic"
                            size="lg"
                            lead
                            space
                            onClick={() => {
                              this.handleLicensesModalOpen(account, 2);
                            }}
                          />
                        </div>
                      ) : null
                    ) : null
                  ) : null}
                  {user.getRolId() == 1 || user.getRolId() == 2 ? (
                    <div data-tip data-for="edit">
                      <Button
                        className="ml-3"
                        icon="user-tag"
                        kind="link"
                        color="basic"
                        size="lg"
                        lead
                        space
                        onClick={() => {
                          this.handleEditRolModalOpen(account);
                        }}
                      />
                    </div>
                  ) : null}
                  {user.getRolId() == 1 || user.getRolId() == 2 ? (
                    <div data-tip data-for="spu">
                      <Button
                        className="ml-3"
                        icon={this.getIconStatus(account.status)}
                        kind="link"
                        color={this.getColorStatus(account.status)}
                        size="lg"
                        lead
                        space
                        onClick={() => {
                          this.handleConfirmationStatusModalOpen(account);
                        }}
                      />
                    </div>
                  ) : null}
                </div>
              ) : (
                <Button
                  iconKasimu="arrow-right"
                  kind="link"
                  color="special"
                  size={this.props.store.viewMobile ? "lg" : "lg"}
                  lead
                  space
                  onClick={() =>
                    this.setState({
                      screen: { actions: true },
                      account: account,
                      titleScreen: (
                        <div>
                          <Text
                            color="white"
                            size={"xxl"}
                            weight="bold"
                            lineBreak
                          >
                            {account.username}
                          </Text>
                        </div>
                      ),
                    })
                  }
                />
              )}
            </div>
          </Column>
        </Columns>
      </Panel>
    );
  }

  renderRow = (accounts) => {
    const arrayAccounts = accounts.toArray();
    return (
      <div
        className="flex flex-col my-1"
        style={{ gap: this.props.store.viewMobile ? 6 : 3 }}
      >
        {arrayAccounts.length === 0 ? (
          <EmptyContent />
        ) : (
          <>
            <Text
              className="is-pulled-right mr-1"
              size={this.props.store.viewMobile ? "md" : "sm"}
              maxLength={this.props.mobile ? 50 : 250}
            >
              {"Resultados: " + accounts.count}
            </Text>

            {arrayAccounts.map((account, idx) => this.renderItemsRow(account))}
          </>
        )}
      </div>
    );
  };

  renderHeader = () => {
    const { accounts } = this.state;
    if (!accounts) return this.renderEmptyContent();
    return !accounts.isOk() ? this.renderLoader() : this.renderBody();
  };

  renderScreenActions() {
    const { account } = this.state;

    const actions = [
      {
        text: "Más información",
        onClick: (event) => this.handleInformationModalOpen(event),
        icon: "search-plus",
      },
      {
        text: "Facturas de aMember",
        onClick: (event) => this.handleInvoicesAMemberModalOpen(event),
        icon: "file-invoice-dollar",
      },
      {
        text: "Agregar licencia",
        onClick: (event) => this.handleLicensesModalOpen(event, 1),
        icon: "user-plus",
      },
      {
        text: "Eliminar licencia",
        onClick: (event) => this.handleLicensesModalOpen(event, 2),
        icon: "user-minus",
      },
      {
        text: "Editar rol",
        onClick: (event) => this.handleEditRolModalOpen(event),
        icon: "user-tag",
      },
      {
        text: "Activar o Suspender usuario",
        onClick: (event) => this.handleConfirmationStatusModalOpen(event),
        icon: this.getIconStatus(account.status),
        color: this.getColorStatus(account.status),
      },
    ];
    return (
      <Screen
        onBack={() => {
          this.setState({ screen: { ...this.state.screen, actions: false } });
        }}
        title={this.state.titleScreen}
      >
        <div
          className="flex flex-col my-1"
          style={{ gap: this.props.store.viewMobile ? 6 : 3 }}
        >
          {actions.map((action, idx) => (
            <Panel
              id={idx + 1}
              color={"gray"}
              padding={this.props.store.viewMobile ? "16px 30px" : "14px 10px"}
              style={{ borderRadius: !this.props.store.viewMobile && 5 }}
            >
              <React.Fragment>
                <Columns isMobile isVCentered isMarginless isGapless>
                  <Column isSize={{ mobile: 10, desktop: 10, tablet: 10 }}>
                    <Columns>
                      <Column isSize={{ mobile: 12, desktop: 6, tablet: 6 }}>
                        <div className="flex items-center">
                          <div
                            className="flex items-center"
                            style={{
                              background: "white",
                              borderRadius: 5,
                              marginRight: 12,
                            }}
                          >
                            <Button
                              icon={action.icon || null}
                              iconKasimu={action.iconKasimu || null}
                              kind="link"
                              color={action.color || "black"}
                              size={this.props.store.viewMobile ? "lg" : "md"}
                              padding="6px 8px"
                            />
                          </div>

                          <div>
                            <Text
                              color="white"
                              size={this.props.store.viewMobile ? "xl" : "lg"}
                              weight="medium"
                              lineBreak
                            >
                              {action.text}
                            </Text>
                          </div>
                        </div>
                      </Column>
                    </Columns>
                  </Column>
                  <Column isSize={{ mobile: 2, desktop: 2, tablet: 2 }}>
                    <div className="is-row" style={{ float: "right" }}>
                      <Button
                        iconKasimu="arrow-right"
                        kind="link"
                        color="special"
                        size={this.props.store.viewMobile ? "lg" : "lg"}
                        lead
                        space
                        onClick={() => action.onClick(account)}
                      />
                    </div>
                  </Column>
                </Columns>
              </React.Fragment>
            </Panel>
          ))}
        </div>
      </Screen>
    );
  }

  renderBody = () => {
    const {
      accounts,
      showInformationModal,
      showInvoicesAMemberModal,
      showLicensesModal,
      showEditRolModal,
      showConfirmationStatusModal,
      currentAccount,
      currentType,
    } = this.state;

    return (
      <React.Fragment>
        {this.state.screen.actions && this.renderScreenActions()}
        <Columns isCentered isGapless>
          <Column isSize={{ mobile: 12, desktop: 12, tablet: 12 }}>
            {this.props.store.viewMobile && (
              <Text
                size={this.props.store.viewMobile ? "lg" : "md"}
                lineBreak
                lead
                weight="black"
              >
                {"Administrador de usuarios"}
              </Text>
            )}
            {this.getPanel(accounts.count)}
            {this.renderRow(accounts)}
            {this.renderPaginator(accounts)}
          </Column>
          {showInformationModal && (
            <InformationModal
              account={currentAccount}
              onClose={this.handleInformationModalClose}
            />
          )}
          {showInvoicesAMemberModal && (
            <InvoicesAMemberModal
              account={currentAccount}
              onClose={this.handleInvoicesAMemberModalClose}
            />
          )}
          {showLicensesModal && (
            <LicensesModal
              account={currentAccount}
              type={currentType}
              title={
                currentType === 1 ? "Agregar licencias" : "Eliminar licencias"
              }
              onClose={this.handleLicensesModalClose}
              onOk={this.reload}
            />
          )}
          {showEditRolModal && (
            <EditRolModal
              account={currentAccount}
              onClose={this.handleEditRolModalClose}
              onOk={this.reload}
            />
          )}
          {showConfirmationStatusModal && (
            <ConfirmationModal
              title={
                currentAccount.status == 1
                  ? "Suspender a " + currentAccount.username
                  : "Activar a " + currentAccount.username
              }
              onClose={this.handleConfirmationStatusModalClose}
              onAccept={() =>
                this.updateStatus(currentAccount.status == 1 ? 2 : 1)
              }
              subtitle={
                currentAccount.status == 1
                  ? "Deseas supender al usuario?"
                  : "Deseas activar al usuario?"
              }
            />
          )}
        </Columns>
        <ReactTooltip id="info" place="bottom" type="dark" effect="float">
          <span>{"Más información"}</span>
        </ReactTooltip>
        <ReactTooltip id="invoices" place="bottom" type="dark" effect="float">
          <span>{"Facturas de aMember"}</span>
        </ReactTooltip>
        <ReactTooltip id="lp" place="bottom" type="dark" effect="float">
          <span>{"Agregar licencia"}</span>
        </ReactTooltip>
        <ReactTooltip id="lr" place="bottom" type="dark" effect="float">
          <span>{"Eliminar licencia"}</span>
        </ReactTooltip>
        <ReactTooltip id="edit" place="bottom" type="dark" effect="float">
          <span>{"Editar rol"}</span>
        </ReactTooltip>
        <ReactTooltip id="spu" place="bottom" type="dark" effect="float">
          <span>{"Activar o Suspender usuario"}</span>
        </ReactTooltip>
      </React.Fragment>
    );
  };

  render() {
    return <React.Fragment>{this.renderHeader()}</React.Fragment>;
  }
}

export default withToastManager(withStore(AccountManagerPage));
