import { Store } from "ks_storybook";
import { ApipHistoryConsultedUsers } from "../models";

class ApipHistoryConsultedUsersStore extends Store {
  model = ApipHistoryConsultedUsers;

  fetchAll() {
    return this.search(
      { per_page: 1000 },
      "apip_history_consulted_users_all",
      true,
      "/apip_history_consulted_users"
    );
  }
}

export default ApipHistoryConsultedUsersStore;
