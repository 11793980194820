import React, { Component } from "react";
import { observer, PropTypes } from "mobx-react";
import withStore from "../../hocs/withStore";
import {
  Modal,
  ModalHeader,
  ModalContent,
  ModalFooter,
  Text,
  Field,
  Columns,
  Column,
  Panel,
} from "ks_storybook";
import moment from "moment";
import { Screen } from "../Common";

@observer
class InformationModal extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  handleClose = () => {
    this.props.onClose && this.props.onClose();
  };

  getStatus = (key) => {
    const status = {
      1: (
        <span style={{ color: "#388e3c", fontWeight: "bold" }}>{"Activo"}</span>
      ),
      2: (
        <span style={{ color: "#fdd835", fontWeight: "bold" }}>
          {"Suspendido"}
        </span>
      ),
    };
    return status[key];
  };

  renderScreen = () => {
    const { account } = this.props;
    return (
      <Screen
        title={
          <div
            className="flex items-center"
            style={{ flexWrap: "wrap", textAlign: "center" }}
          >
            <Text lineBreak size="xxl" weight="bold">
              {"Más Información"}
            </Text>
          </div>
        }
        onBack={this.handleClose}
      >
        <Panel color="gray" className="my-1" padding={"32px 18px"}>
          <div
            className="is-row"
            style={{ flexWrap: "wrap", alignItems: "center" }}
          >
            <Text
              size={this.props.store.viewMobile ? "xl" : "lg"}
              className="mr-1"
              weight="medium"
              upper
            >
              {"Id" + ": "}
            </Text>
            <Text
              size={this.props.store.viewMobile ? "xl" : "lg"}
              color="special"
              multiline
            >
              {account.id}
            </Text>
          </div>
          <div
            className="is-row"
            style={{ flexWrap: "wrap", alignItems: "center" }}
          >
            <Text
              size={this.props.store.viewMobile ? "xl" : "lg"}
              className="mr-1"
              weight="medium"
              upper
            >
              {"Contraseña" + ": "}
            </Text>
            <Text
              size={this.props.store.viewMobile ? "xl" : "lg"}
              color="special"
              multiline
            >
              {account.encrypted_password}
            </Text>
          </div>
          <div
            className="is-row"
            style={{ flexWrap: "wrap", alignItems: "center" }}
          >
            <Text
              size={this.props.store.viewMobile ? "xl" : "lg"}
              className="mr-1"
              weight="medium"
              upper
            >
              {"Email" + ": "}
            </Text>
            <Text
              size={this.props.store.viewMobile ? "xl" : "lg"}
              color="special"
              multiline
            >
              {account.email === ""
                ? this.props.store.language.label89
                : account.email}
            </Text>
          </div>
          <div
            className="is-row"
            style={{ flexWrap: "wrap", alignItems: "center" }}
          >
            <Text
              size={this.props.store.viewMobile ? "xl" : "lg"}
              className="mr-1"
              weight="medium"
              upper
            >
              {"Estado del usuario" + ": "}
            </Text>
            <Text
              size={this.props.store.viewMobile ? "xl" : "lg"}
              color="special"
              multiline
            >
              {this.getStatus(account.status)}
            </Text>
          </div>

          {account.preference ? (
            <div
              className="is-row"
              style={{ flexWrap: "wrap", alignItems: "center" }}
            >
              <Text
                size={this.props.store.viewMobile ? "xl" : "lg"}
                className="mr-1"
                weight="medium"
                upper
              >
                {this.props.store.language.label91 + ": "}
              </Text>
              <Text
                size={this.props.store.viewMobile ? "xl" : "lg"}
                color="special"
                multiline
              >
                {account.preference === null
                  ? this.props.store.language.label92
                  : account.preference.pin === 0
                  ? this.props.store.language.label92
                  : account.preference.pin}
              </Text>
            </div>
          ) : null}

          {account.suscription ? (
            <div
              className="is-row"
              style={{ flexWrap: "wrap", alignItems: "center" }}
            >
              <Text
                size={this.props.store.viewMobile ? "xl" : "lg"}
                className="mr-1"
                weight="medium"
              >
                {"Fin de la suscripción ".toUpperCase()}
                <small>{"(Tentativa, depende de aMember)"}</small>
                {": "}
              </Text>

              <Text
                size={this.props.store.viewMobile ? "xl" : "lg"}
                color="special"
                multiline
              >
                {moment(account.suscription.expiration_date).format(
                  "DD-MM-YYYY"
                )}
              </Text>
            </div>
          ) : null}

          <div
            className="is-row"
            style={{ flexWrap: "wrap", alignItems: "center" }}
          >
            <Text
              size={this.props.store.viewMobile ? "xl" : "lg"}
              className="mr-1"
              weight="medium"
              upper
            >
              {"Fecha de creación" + ": "}
            </Text>
            <Text
              size={this.props.store.viewMobile ? "xl" : "lg"}
              color="special"
              multiline
            >
              {moment(account.created_at).format("DD-MM-YYYY HH:mm:ss")}
            </Text>
          </div>

          <div
            className="is-row"
            style={{ flexWrap: "wrap", alignItems: "center" }}
          >
            <Text
              size={this.props.store.viewMobile ? "xl" : "lg"}
              className="mr-1"
              weight="medium"
              upper
            >
              {"Fecha de modificación" + ": "}
            </Text>
            <Text
              size={this.props.store.viewMobile ? "xl" : "lg"}
              color="special"
              multiline
            >
              {moment(account.updated_at).format("DD-MM-YYYY HH:mm:ss")}
            </Text>
          </div>
        </Panel>
      </Screen>
    );
  };

  render() {
    const { account } = this.props;
    return this.props.store.viewMobile ? (
      <Modal
        show
        onClose={this.handleClose}
        width={this.props.store.viewMobile ? "650px" : "90%"}
        height={this.props.store.viewMobile ? "300px" : "auto"}
      >
        <ModalHeader>
          <div className="flex items-center" style={{ flexWrap: "wrap" }}>
            <Text
              lineBreak
              size={this.props.store.viewMobile ? "md" : "sm"}
              lead
              weight="black"
              className="mr-2"
            >
              {this.props.store.language.label87}
            </Text>
            <Text
              lineBreak
              size={this.props.store.viewMobile ? "md" : "sm"}
              lead
              weight="regular"
              className="mr-2"
            >
              {account.username}
            </Text>
          </div>
        </ModalHeader>
        <ModalContent>
          <div
            className="is-row"
            style={{ flexWrap: "wrap", alignItems: "center" }}
          >
            <Text
              size={this.props.store.viewMobile ? "xl" : "lg"}
              className="mr-1"
              weight="medium"
              upper
            >
              {"Id" + ": "}
            </Text>
            <Text
              size={this.props.store.viewMobile ? "xl" : "lg"}
              color="special"
              multiline
            >
              {account.id}
            </Text>
          </div>
          <div
            className="is-row"
            style={{ flexWrap: "wrap", alignItems: "center" }}
          >
            <Text
              size={this.props.store.viewMobile ? "xl" : "lg"}
              className="mr-1"
              weight="medium"
              upper
            >
              {"Contraseña" + ": "}
            </Text>
            <Text
              size={this.props.store.viewMobile ? "xl" : "lg"}
              color="special"
              multiline
            >
              {account.encrypted_password}
            </Text>
          </div>
          <div
            className="is-row"
            style={{ flexWrap: "wrap", alignItems: "center" }}
          >
            <Text
              size={this.props.store.viewMobile ? "xl" : "lg"}
              className="mr-1"
              weight="medium"
              upper
            >
              {"Email" + ": "}
            </Text>
            <Text
              size={this.props.store.viewMobile ? "xl" : "lg"}
              color="special"
              multiline
            >
              {account.email === ""
                ? this.props.store.language.label89
                : account.email}
            </Text>
          </div>
          <div
            className="is-row"
            style={{ flexWrap: "wrap", alignItems: "center" }}
          >
            <Text
              size={this.props.store.viewMobile ? "xl" : "lg"}
              className="mr-1"
              weight="medium"
              upper
            >
              {"Estado del usuario" + ": "}
            </Text>
            <Text
              size={this.props.store.viewMobile ? "xl" : "lg"}
              color="special"
              multiline
            >
              {this.getStatus(account.status)}
            </Text>
          </div>

          {account.preference ? (
            <div
              className="is-row"
              style={{ flexWrap: "wrap", alignItems: "center" }}
            >
              <Text
                size={this.props.store.viewMobile ? "xl" : "lg"}
                className="mr-1"
                weight="medium"
                upper
              >
                {this.props.store.language.label91 + ": "}
              </Text>
              <Text
                size={this.props.store.viewMobile ? "xl" : "lg"}
                color="special"
                multiline
              >
                {account.preference === null
                  ? this.props.store.language.label92
                  : account.preference.pin === 0
                  ? this.props.store.language.label92
                  : account.preference.pin}
              </Text>
            </div>
          ) : null}

          {account.suscription ? (
            <div
              className="is-row"
              style={{ flexWrap: "wrap", alignItems: "center" }}
            >
              <Text
                size={this.props.store.viewMobile ? "xl" : "lg"}
                className="mr-1"
                weight="medium"
              >
                {"Fin de la suscripción ".toUpperCase()}
                <small>{"(Tentativa, depende de aMember)"}</small>
                {": "}
              </Text>

              <Text
                size={this.props.store.viewMobile ? "xl" : "lg"}
                color="special"
                multiline
              >
                {moment(account.suscription.expiration_date).format(
                  "DD-MM-YYYY"
                )}
              </Text>
            </div>
          ) : null}

          <div
            className="is-row"
            style={{ flexWrap: "wrap", alignItems: "center" }}
          >
            <Text
              size={this.props.store.viewMobile ? "xl" : "lg"}
              className="mr-1"
              weight="medium"
              upper
            >
              {"Fecha de creación" + ": "}
            </Text>
            <Text
              size={this.props.store.viewMobile ? "xl" : "lg"}
              color="special"
              multiline
            >
              {moment(account.created_at).format("DD-MM-YYYY HH:mm:ss")}
            </Text>
          </div>

          <div
            className="is-row"
            style={{ flexWrap: "wrap", alignItems: "center" }}
          >
            <Text
              size={this.props.store.viewMobile ? "xl" : "lg"}
              className="mr-1"
              weight="medium"
              upper
            >
              {"Fecha de modificación" + ": "}
            </Text>
            <Text
              size={this.props.store.viewMobile ? "xl" : "lg"}
              color="special"
              multiline
            >
              {moment(account.updated_at).format("DD-MM-YYYY HH:mm:ss")}
            </Text>
          </div>
        </ModalContent>
        <ModalFooter></ModalFooter>
      </Modal>
    ) : (
      this.renderScreen()
    );
  }
}

InformationModal.propTypes = {
  onClose: PropTypes.func,
};

InformationModal.defaultProps = {
  onClose: null,
};

export default withStore(InformationModal);
