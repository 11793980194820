import { Store } from "ks_storybook";
import { Catalogs } from "../models";

class CatalogsStore extends Store {
  model = Catalogs;

  fetchAll() {
    return this.search({ per_page: 1000 }, "catalogs_all", true, "/catalogs");
  }
}

export default CatalogsStore;
