import { Store } from "ks_storybook";
import { Playlist } from "../models";

class PlaylistsStore extends Store {
  model = Playlist;

  fetchAllParams(per_page, filters, orders, search) {
    return this.search(
      {
        per_page: per_page,
        filters: JSON.stringify(filters),
        orders: JSON.stringify(orders),
        search: search,
      },
      "playlists_get_all_params",
      true,
      "/playlists/get_all_params"
    );
  }

  fetchPage(per_page, page, filters, orders, search) {
    return this.search(
      {
        per_page: per_page,
        page: page,
        filters: JSON.stringify(filters),
        orders: JSON.stringify(orders),
        search: search,
      },
      "playlists_get_all_params",
      true,
      "/playlists/get_all_params"
    );
  }
}

export default PlaylistsStore;
