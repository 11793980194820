import { Store } from "ks_storybook";
import { UserGroup } from "../models";

class UserGroupStore extends Store {
  model = UserGroup;

  fetchGroups(filters = {}) {
    return this.search(filters, "groups", true, "/users_groups");
  }

  fetchAll() {
    return this.search({}, "groups_all", true, "/users_groups");
  }
}

export default UserGroupStore;
