import { Store } from "ks_storybook";
import { SongMoods } from "../models";

class SongMoodsStore extends Store {
  model = SongMoods;

  fetchAll() {
    return this.search(
      { per_page: 100 },
      "song_moods_all",
      true,
      "/song_moods"
    );
  }
}

export default SongMoodsStore;
