import { Store } from "ks_storybook";
import { MusicLists } from "../models";

class MusicListsStore extends Store {
  model = MusicLists;

  fetchAllParams(per_page, orders, search) {
    return this.search(
      { per_page: per_page, orders: JSON.stringify(orders), search: search },
      "music_lists_get_all_params",
      true,
      "/music_lists/get_all_params"
    );
  }

  fetchPage(per_page, page, orders, search) {
    return this.search(
      {
        per_page: per_page,
        page: page,
        orders: JSON.stringify(orders),
        search: search,
      },
      "music_lists_get_all_params_page",
      true,
      "/music_lists/get_all_params"
    );
  }
}

export default MusicListsStore;
