import { Store } from "ks_storybook";
import { SongLanguage } from "../models";

class SongLanguageStore extends Store {
  model = SongLanguage;

  fetchAll() {
    return this.search(
      { per_page: 1000 },
      "songs_leguages_all",
      true,
      "/songs_leguages"
    );
  }
}

export default SongLanguageStore;
