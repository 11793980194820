import { Store } from "ks_storybook";
import { RecordLabel } from "../models";

class RecordLabelStore extends Store {
  model = RecordLabel;

  fetchAllParams(per_page, orders, search) {
    return this.search(
      { per_page: per_page, orders: JSON.stringify(orders), search: search },
      "record_label_get_all_params",
      true,
      "/record_label/get_all_params"
    );
  }

  fetchPage(per_page, page, orders, search) {
    return this.search(
      {
        per_page: per_page,
        page: page,
        orders: JSON.stringify(orders),
        search: search,
      },
      "record_label_get_all_params_page",
      true,
      "/record_label/get_all_params"
    );
  }
}

export default RecordLabelStore;
