import { Store } from "ks_storybook";
import { Blocks } from "../models";

class BlocksStore extends Store {
  model = Blocks;

  fetchSearch(per_page, playlist_id) {
    return this.search(
      { per_page: per_page, playlist_id: playlist_id },
      "blocks_search",
      true,
      "/blocks/get_search_playlist"
    );
  }

  movePage(per_page, page, playlist_id) {
    return this.search(
      { per_page: per_page, page: page, playlist_id: playlist_id },
      "blocks_search_page",
      true,
      "/blocks/get_search_playlist"
    );
  }
}

export default BlocksStore;
