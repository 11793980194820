import { Store } from "ks_storybook";
import { TranslationCategoriesPlaylists } from "../models";

class TranslationCategoriesPlaylistsStore extends Store {
  model = TranslationCategoriesPlaylists;

  fetchSearch(per_page, categories_playlist_id) {
    return this.search(
      { per_page: per_page, categories_playlist_id: categories_playlist_id },
      "translation_categoriesplaylists_search",
      true,
      "/translation_categoriesplaylists/get_search"
    );
  }

  movePage(per_page, page, categories_playlist_id) {
    return this.search(
      {
        per_page: per_page,
        page: page,
        categories_playlist_id: categories_playlist_id,
      },
      "translation_categoriesplaylists_search_page",
      true,
      "/translation_categoriesplaylists/get_search"
    );
  }
}

export default TranslationCategoriesPlaylistsStore;
