import { Store } from "ks_storybook";
import { CategoryPlaylist } from "../models";

class CategoriesPlaylistsStore extends Store {
  model = CategoryPlaylist;

  fetchSearch(per_page, playlist_id) {
    return this.search(
      { per_page: per_page, playlist_id: playlist_id },
      "categories_playlist_search",
      true,
      "/categories_playlist/get_search_playlist"
    );
  }

  movePage(per_page, page, playlist_id) {
    return this.search(
      { per_page: per_page, page: page, playlist_id: playlist_id },
      "categories_playlist_search_page",
      true,
      "/categories_playlist/get_search_playlist"
    );
  }
}

export default CategoriesPlaylistsStore;
