import { Store } from "ks_storybook";
import { SongsAssignedUserStyles } from "../models";

class SongsAssignedUserStylesStore extends Store {
  model = SongsAssignedUserStyles;

  fetchAll() {
    return this.search(
      { per_page: 1000 },
      "songs_assigned_user_styles_all",
      true,
      "/songs_assigned_user_styles"
    );
  }

  fetchAllParams(per_page, filters, orders, search) {
    return this.search(
      {
        per_page: per_page,
        filters: JSON.stringify(filters),
        orders: JSON.stringify(orders),
        search: search,
      },
      "songs_assigned_user_styles_get_all_params",
      true,
      "/songs_assigned_user_styles/get_all_params"
    );
  }

  fetchPage(per_page, page, filters, orders, search) {
    return this.search(
      {
        per_page: per_page,
        page: page,
        filters: JSON.stringify(filters),
        orders: JSON.stringify(orders),
        search: search,
      },
      "songs_assigned_user_styles_get_all_params",
      true,
      "/songs_assigned_user_styles/get_all_params"
    );
  }
}

export default SongsAssignedUserStylesStore;
