import React, { Component } from "react";
import { observer, PropTypes } from "mobx-react";
import withStore from "../../hocs/withStore";
import {
  Field,
  TextInput,
  Modal,
  Button,
  ModalHeader,
  ModalContent,
  ModalFooter,
  Text,
} from "ks_storybook";
import { Select } from "../Select";
import { withToastManager } from "react-toast-notifications";

@observer
class EditRolModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isSaving: false,
    };
    this.params = this.newParams();
  }

  newParams = () => {
    const params = {
      user_id: this.props.account.id,
      ks_rol_id: null,
    };
    return params;
  };

  handleChangeFiled = (sender, value, name, validation) => {
    this.params[name] = value;
    this.setState({ isSaving: false });
  };

  handleClose = () => {
    this.props.onClose && this.props.onClose();
  };

  handleSave = () => {
    const { toastManager } = this.props;
    this.setState({ isSaving: true }, () => {
      this.props.store.kasimuAPIClient
        .putOne(
          "/users/put_rol_user",
          "?user_id=" +
            this.params.user_id +
            "&ks_rol_id=" +
            this.params.ks_rol_id
        )
        .then(
          (res) => {
            toastManager.add("Se modifico exitosamente el rol.", {
              appearance: "success",
              autoDismiss: true,
            });
            this.props.onOk && this.props.onOk();
          },
          (error) => {
            let str = error.toString();
            str = str.replace("Error: ", "");
            let mydatas = JSON.parse(str);
            this.setState({
              messageError: "Error, algo salio mal al cambiar el rol.",
            });
            this.setState({ isSaving: false });
          }
        )
        .catch((error) => {
          toastManager.add("Error, algo salio mal al cambiar el rol.", {
            appearance: "error",
            autoDismiss: true,
          });
        });
    });
  };

  renderButtons = () => {
    return (
      <nav class="level is-mobile">
        <div className="level-left" />
        <div className="level-right mb-2">
          {this.state.isSaving ? (
            <Button
              className="is-pulled-right px-3"
              size={this.props.store.viewMobile ? "lg" : "md"}
              disabled
              icon="spinner"
              pulse
            >
              {this.props.store.language.label81}
            </Button>
          ) : (
            <Button
              size={this.props.store.viewMobile ? "lg" : "md"}
              disabled={!this.params.ks_rol_id}
              className="is-pulled-right px-3"
              onClick={this.handleSave}
            >
              <strong>{this.props.store.language.label80}</strong>
            </Button>
          )}
        </div>
      </nav>
    );
  };

  handleSelectChange = (value, sender) => {
    const val = value.value;
    switch (sender.name) {
      case "selectRol":
        this.params["ks_rol_id"] = val;
        this.setState({ isSaving: false });
        break;
      default:
        break;
    }
  };

  render() {
    const { account } = this.props;
    const rowOptions = [
      { value: 5, label: "ADMINISTRADOR" },
      { value: 8, label: "USUARIO-INDIVIDUAL" },
    ];
    return (
      <Modal
        show
        onClose={this.handleClose}
        width={this.props.store.viewMobile ? "400px" : "90%"}
      >
        <ModalHeader>
          <Text
            lineBreak
            size={this.props.store.viewMobile ? "md" : "sm"}
            lead
            weight="black"
          >
            {"Editar el rol"}
          </Text>
        </ModalHeader>
        <ModalContent>
          <div className="my-1">
            <Field
              marginH={this.props.store.viewMobile && "4px"}
              label={"Rol"}
              size={this.props.store.viewMobile ? "xl" : "lg"}
              noteSize={this.props.store.viewMobile ? "sm" : "xs"}
              weight="medium"
            >
              <Select
                name={"selectRol"}
                onChange={this.handleSelectChange}
                autocomplete="nope"
                defaultValue={
                  rowOptions[
                    rowOptions
                      .map(function (e) {
                        return e.value;
                      })
                      .indexOf(account.ks_rol.ks_rol.id)
                  ]
                }
                options={rowOptions}
                backgroundColor="black"
                separatorColor="black"
                placeholder
                size={this.props.store.viewMobile ? "lg" : "md"}
                isMobile={!this.props.store.viewMobile}
                className="my-1"
              />
            </Field>
          </div>
        </ModalContent>
        <ModalFooter>{this.renderButtons()}</ModalFooter>
      </Modal>
    );
  }
}

EditRolModal.propTypes = {
  type: PropTypes.number,
  onClose: PropTypes.func,
  onOk: PropTypes.func,
};

EditRolModal.defaultProps = {
  type: null,
  onClose: null,
  onOk: null,
};

export default withToastManager(withStore(EditRolModal));
