import { Store } from "ks_storybook";
import { TranslationSongStyles } from "../models";

class TranslationSongStylesStore extends Store {
  model = TranslationSongStyles;

  fetchSearch(per_page, song_styles_id) {
    return this.search(
      { per_page: per_page, song_styles_id: song_styles_id },
      "translation_song_styles_search",
      true,
      "/translation_song_styles/get_search"
    );
  }

  movePage(per_page, page, song_styles_id) {
    return this.search(
      { per_page: per_page, page: page, song_styles_id: song_styles_id },
      "translation_song_styles_search_page",
      true,
      "/translation_song_styles/get_search"
    );
  }
}

export default TranslationSongStylesStore;
