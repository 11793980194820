import { Store } from "ks_storybook";
import { MusicListsContents } from "../models";

class MusicListsContentsStore extends Store {
  model = MusicListsContents;

  fetchSearch(per_page, music_lists_id) {
    return this.search(
      { per_page: per_page, music_lists_id: music_lists_id },
      "music_lists_content_search",
      true,
      "/music_lists_content/get_search"
    );
  }

  movePage(per_page, page, music_lists_id) {
    return this.search(
      { per_page: per_page, page: page, music_lists_id: music_lists_id },
      "music_lists_content_search_page",
      true,
      "/music_lists_content/get_search"
    );
  }
}

export default MusicListsContentsStore;
