import { Store } from "ks_storybook";
import { ReportsSingers } from "../models";

class ReportsSingersStore extends Store {
  model = ReportsSingers;

  fetchSearch(per_page, singer_id) {
    return this.search(
      { per_page: per_page, singer_id: singer_id },
      "reports_singers_search",
      true,
      "/reports_singers/get_search"
    );
  }

  movePage(per_page, page, singer_id) {
    return this.search(
      { per_page: per_page, page: page, singer_id: singer_id },
      "reports_singers_search_page",
      true,
      "/reports_singers/get_search"
    );
  }
}

export default ReportsSingersStore;
