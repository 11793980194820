import React, { Component } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { withRouter } from "react-router";
import { observer } from "mobx-react";
import withStore from "../../hocs/withStore";
import {
  MobileLayout,
  MobileLayoutHeader,
  MobileLayoutHeaderEnd,
  MobileLayoutHeaderStart,
  MobileLayoutHeaderContent,
  MobileLayoutContent,
  Button,
  Dropdown,
  DropdownSideDrawer,
  LayoutSide,
  Sidebar,
  Text,
} from "ks_storybook";
import { SignIn } from "../Session";
import { UsersManagerRouter, UsersManagerMenu } from "../UsersManager";
import LoggedUserDropdown from "./components/LoggedUserDropdown";

// remove this later, when all routes exist -----------
//const FakeContent = withRouter(props => <div>{props.location.pathname}</div>);

@observer
class MobileAppRouter extends Component {
  handleClick = () => {
    if (this.props.store.toggleMenu) {
      this.props.store.toggleMenu = false;
    } else {
      this.props.store.toggleMenu = true;
    }
  };

  render() {
    const path = this.props.match.path;
    const toggle = this.props.store.toggleMenu;
    const root = "/usersmanager/";
    if (this.props.store.isLoggedIn || this.props.store.isLoggingIn) {
      const uiSettings = this.props.store.ui.settings;

      if (uiSettings.isEmpty()) return null;

      return (
        <React.Fragment>
          <div className="ks--layout">
            <MobileLayout showSide={this.props.store.ui.showMainSide}>
              <MobileLayoutHeader>
                <MobileLayoutHeaderStart>
                  <Dropdown
                    isOpen={toggle}
                    handleToggle={this.handleClick}
                    menu
                  >
                    <Button
                      kind="link"
                      onClick={this.handleClick}
                      color="basic"
                      iconKasimu="bars"
                      size="xl"
                      space
                    ></Button>
                    <DropdownSideDrawer
                      panel={false}
                      style={{
                        height: "100%",
                        position: "fixed",
                        top: "0",
                        left: "0",
                        maxWidth: "80%",
                      }}
                    >
                      <Sidebar mobile>
                        <Switch>
                          <Route
                            path={`${path}${root}`}
                            render={(props) => (
                              <UsersManagerMenu
                                {...props}
                                handleToogle={this.handleClick}
                              />
                            )}
                          />
                        </Switch>
                      </Sidebar>
                    </DropdownSideDrawer>
                  </Dropdown>
                </MobileLayoutHeaderStart>
                <MobileLayoutHeaderContent>
                  <Text size="xxl" weight="bold">
                    {this.props.store.menuTitle}
                  </Text>
                </MobileLayoutHeaderContent>
                <MobileLayoutHeaderEnd>
                  <LoggedUserDropdown mobile />
                </MobileLayoutHeaderEnd>
              </MobileLayoutHeader>
              <MobileLayoutContent>
                <Switch>
                  <Route
                    exact
                    path={`${path}${root}`}
                    component={UsersManagerRouter}
                  />
                  <UsersManagerRouter defaultComponent={UsersManagerRouter} />
                  <Redirect
                    to={`${path}${root}`}
                    component={UsersManagerRouter}
                  />
                </Switch>
              </MobileLayoutContent>
              {/*<MobileLayoutMenu>
                             <MobileLayoutMenuLink to="/app" icon="user-clock"/>
                             <MobileLayoutMenuLink to="/app/controlpanel/users/accounts" icon="users"/>
                             
                             <MobileLayoutMenuLink to="/app/controlpanel/playlist_plans" icon="list-ul"/>
                             <MobileLayoutMenuLink to="/app/controlpanel/users/preferences" icon="users-cog"/>
                        </MobileLayoutMenu>*/}
            </MobileLayout>
          </div>
        </React.Fragment>
      );
    }

    return (
      <Switch>
        <Redirect to={`/session/signIn`} component={SignIn} />
      </Switch>
    );
  }
}

export default withStore(withRouter(MobileAppRouter));
