import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { withRouter } from 'react-router';
import { observer } from 'mobx-react';


import withStore from '../../hocs/withStore';


// pages
import { SignIn } from '../Session';


import AppRouter from '../AppRouter/AppRouter';



@observer
class SessionRouter extends Component {


  componentDidMount() {
    document.title = `Kasimu`;
  }


  render() {
    if ( !this.props.store.isLoggedIn && !this.props.store.isLoggingIn ) {
      return (

              <Switch>
                <Route exact path={`${this.props.match.path}/signIn`} component={ SignIn } />

                <Redirect to={`${this.props.match.path}/signIn`} component={ SignIn } /> 
              </Switch>

        );

    }

    // already logged in
    return (
      <Switch>
        <Redirect to={`/app`} component={ AppRouter } /> 
      </Switch>
      );

  }
}

export default withStore(withRouter(SessionRouter));
