import { Model } from "ks_storybook";
import { computed, observable, action, toJS } from "mobx";

class User extends Model {
  urlRoot = "/users";

  saveAsAccount() {
    return this._store.saveAsAccount(this);
  }

  getRolName() {
    if (this.ks_rol) {
      const js_ks_rol = toJS(this.ks_rol);
      return js_ks_rol.ks_rol.name;
    }
    return "";
  }

  getRolId() {
    if (this.ks_rol) {
      return this.ks_rol.ks_rol.id;
    }
    return "";
  }

  getPermissions() {
    if (this.ks_permissions) {
      const array_ks_permissions = toJS(this.ks_permissions);
      if (array_ks_permissions.length != 0) return array_ks_permissions;
      return [];
    }
  }

  checkPermission(permissions) {
    if (this.ks_permissions) {
      const array_ks_permissions = toJS(this.ks_permissions);
      if (array_ks_permissions.length != 0) {
        var i;
        for (i = 0; i < array_ks_permissions.length; i++) {
          if (array_ks_permissions[i].ks_permissions_id === permissions) {
            return true;
          }
        }
      }
      return false;
    }
    return false;
  }
}

export default User;
