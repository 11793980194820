import { Store } from "ks_storybook";
import { ReportsSongs } from "../models";

class ReportsSongsStore extends Store {
  model = ReportsSongs;

  fetchSearch(per_page, song_id) {
    return this.search(
      { per_page: per_page, song_id: song_id },
      "reports_songs_search",
      true,
      "/reports_songs/get_search"
    );
  }

  movePage(per_page, page, song_id) {
    return this.search(
      { per_page: per_page, page: page, song_id: song_id },
      "reports_songs_search_page",
      true,
      "/reports_songs/get_search"
    );
  }
}

export default ReportsSongsStore;
