import React, { Component } from "react";
import { observer, PropTypes } from "mobx-react";
import withStore from "../../../hocs/withStore";
import {
  Modal,
  Text,
  Button,
  ModalHeader,
  ModalContent,
  ModalFooter,
} from "ks_storybook";
import { withToastManager } from "react-toast-notifications";

@observer
class DeleteConfirmationModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isDeleting: false,
    };
  }

  handleAccept = () => {
    const { currentObj, messageSuccess, messageError, toastManager } =
      this.props;
    this.setState({ isDeleting: true }, () => {
      currentObj.delete().andThen((response, responseError) => {
        if (responseError) {
          toastManager.add(messageError, {
            appearance: "error",
            autoDismiss: true,
          });
        } else {
          toastManager.add(messageSuccess, {
            appearance: "success",
            autoDismiss: true,
          });
          this.props.onAccept && this.props.onAccept();
        }
      });
    });
  };

  handleClose = () => {
    this.props.onClose && this.props.onClose();
  };

  render() {
    const { title, subtitle } = this.props;
    return (
      <Modal
        show
        onClose={this.handleClose}
        width={this.props.store.viewMobile ? "640px" : "90%"}
        height={this.props.store.viewMobile ? "240px" : "auto"}
        invert
      >
        <ModalHeader>
          <Text
            upper
            size={this.props.store.viewMobile ? "md" : "sm"}
            lead
            weight="strong"
            lineBreak
          >
            {title}
          </Text>
        </ModalHeader>
        <ModalContent>
          <Text size="xl" lineBreak>
            {subtitle}
          </Text>
        </ModalContent>
        <ModalFooter>
          <nav class="level is-mobile">
            <div className="level-left" />
            <div className="level-right ">
              {this.state.isDeleting ? null : (
                <Button
                  kind="outline"
                  upper
                  size={this.props.store.viewMobile ? "lg" : "md"}
                  className="px-3"
                  onClick={this.handleClose}
                >
                  {this.props.store.language.label427}
                </Button>
              )}
              {this.state.isDeleting ? (
                <Button
                  upper
                  size={this.props.store.viewMobile ? "lg" : "md"}
                  className="px-3"
                  disabled
                  icon="spinner"
                  pulse
                >
                  {this.props.store.language.label130}
                </Button>
              ) : (
                <Button
                  upper
                  size="lg"
                  className="px-3"
                  onClick={this.handleAccept}
                >
                  {this.props.store.language.label129}
                </Button>
              )}
            </div>
          </nav>
        </ModalFooter>
      </Modal>
    );
  }
}

DeleteConfirmationModal.propTypes = {
  currentObj: PropTypes.object,
  messageSuccess: PropTypes.string,
  messageError: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  onAccept: PropTypes.func,
  onClose: PropTypes.func,
};

DeleteConfirmationModal.defaultProps = {
  currentObj: null,
  messageSuccess: null,
  messageError: null,
  title: null,
  subtitle: null,
  onAccept: null,
  onClose: null,
};

export default withStore(withToastManager(DeleteConfirmationModal));
