import { Store } from "ks_storybook";
import { Songs } from "../models";

class SongsStore extends Store {
  model = Songs;

  fetchAllParams(per_page, filters, orders, search) {
    return this.search(
      { per_page: per_page, filters: filters, orders: orders, search: search },
      "songs_get_all_params",
      true,
      "/songs/get_all_params"
    );
  }

  fetchPage(per_page, page, filters, orders, search) {
    return this.search(
      {
        per_page: per_page,
        page: page,
        filters: filters,
        orders: orders,
        search: search,
      },
      "songs_get_all_params",
      true,
      "/songs/get_all_params"
    );
  }
}

export default SongsStore;
