/****
 * vdj to mp3 
 ****/


export const vdjToMp3Src = (buffer) => {
    let mp3Buffer = new Uint8Array(buffer);
    let mask = str2ab("VDJ Format Version 1.1");

    for (let i = 0; i < mp3Buffer.length; ++i)
      mp3Buffer[i] = mp3Buffer[i] ^ mask[i % 32];
      
    return bufferToSrc(mp3Buffer);
  };

  export const bufferToSrc = (buffer) => {
    let blob = new Blob([buffer], { 'type': 'audio/mp3' });
    return URL.createObjectURL(blob);
  };
  
  
  function str2ab(str) {
    var keyLen = 32;
    var buf = new ArrayBuffer(keyLen);
    var bufView = new Uint8Array(buf);
  
    for (var i = 0; i < str.length; i++) {
      bufView[i] = str.charCodeAt(i);
    }
  
    for (var i = str.length; i < keyLen; i++) {
      bufView[i] = 5;
    }
  
    return bufView;
  }