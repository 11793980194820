import { observable } from "mobx";
import { Store } from "ks_storybook";
import { UISettings } from "../models";
import { vdjToMp3Src } from "../libs/songslib";
import { UsersListenedSongs } from "../models";
import WaveSurfer from "wavesurfer.js";
import moment from "moment";
export default class UIStore extends Store {
  model = UISettings;
  urlRoot = "UI";
  //_songDownloadLimit = 25;
  _downloadingPrevSongs = false;
  _downloadingNextSongs = true;
  _host = "https://kasimu.eu/musica_online/";

  @observable audioDriver = new Audio();
  @observable wavesurfer = null;
  @observable showMainSide = false;
  @observable showPlayer = false;
  @observable songs = [];
  @observable songsCount = 0;
  @observable currentSong;
  @observable currentIndice = 0;
  @observable isPlaying = false;
  @observable currentDuration = null;
  @observable durationSecs = 0;
  @observable currentProgressTime = null;
  @observable currentProgress = 0;
  @observable endDownload = false;
  @observable listennigTime = 0;
  @observable initialMoment = null;
  @observable endMoment = null;

  constructor(adapter, appStore) {
    super(adapter, appStore);

    var myHeaders = new Headers();
    myHeaders.append("pragma", "no-cache");
    myHeaders.append("cache-control", "no-store");

    this.downloadedSongs = [];

    this.interval = 1000;
    setInterval(this.updateProgress, this.interval);
  }

  wave = () => {
    const that = this;
    that.wavesurfer = WaveSurfer.create({
      container: "#waveform",
      waveColor: "white",
      progressColor: "#FF3F1F",
      responsive: true,
      hideScrollbar: true,
      barWidth: 1,
      barHeight: 0.6,
      cursorWidth: 0,
      height: 100,
      backend: "MediaElement",
    });
    document.body.onkeydown = (event) => {
      let keyCode = event.keyCode;
      switch (keyCode) {
        case 39: // avanzar barra 32
          if (this.showPlayer && this.isPlaying) {
            if (this.wavesurfer.getCurrentTime()) {
              this.wavesurfer.skipForward(15); // 15 segs para delante
            }
            return false;
          }
          break;
        case 37: //  reproceder
          if (this.showPlayer && this.isPlaying) {
            if (this.wavesurfer.getCurrentTime()) {
              this.wavesurfer.skipBackward(15); // 15 segs para atras
            }
            return false;
          }
          break;
        case 40: // siguiente
          if (this.showPlayer && this.isPlaying) {
            this.next();
            return false;
          }
          break;
        case 38: // anterior
          if (this.showPlayer && this.isPlaying) {
            this.prev();
            return false;
          }
          break;
      }
    };
  };

  getSongsAll = (per_page, filters, orders, search) => {
    this.wavesurfer = null;
    this.showPlayer = false;
    this.songsCount = 0;
    this.currentSong = null;
    this.endDownload = true;
    this._downloadingNextSongs = false;
    this.freeMemory();
    var songs = this.appStore.songs.fetchAllParams(
      per_page,
      JSON.stringify(filters),
      JSON.stringify(orders),
      search
    );
    songs.andThen((res) => {
      this.songsCount = res.count;
      this.songs = res.toArray();
    });
    return songs;
  };

  moveSongsPage = (per_page, page, filters, orders, search) => {
    this.wavesurfer = null;
    this.showPlayer = false;
    if (
      !filters.date_from &&
      !filters.date_to &&
      !filters.styles_id &&
      !filters.preference_from &&
      !filters.preference_to &&
      !filters.mood_id &&
      filters.service == -1 &&
      !filters._status &&
      !filters.music_list &&
      !filters.explicit &&
      !filters.user_preference &&
      !filters.not_assigned &&
      !filters.deleted &&
      !filters.marked_songs &&
      !filters.year &&
      !filters.user_id &&
      !filters.my_tag_id &&
      !filters.search_type_by_user &&
      !filters.date_from_user &&
      !filters.date_to_user
    ) {
      filters = null;
    }
    this.currentSong = null;
    this.endDownload = true;
    this._downloadingNextSongs = false;
    this.freeMemory();
    var songs = this.appStore.songs.fetchPage(
      per_page,
      page,
      JSON.stringify(filters),
      JSON.stringify(orders),
      search
    );
    songs.andThen((res) => {
      this.songs = res.toArray();
    });
    return songs;
  };

  freeMemory = () => {
    this.downloadedSongs = [];
  };

  get settings() {
    if (this.appStore.loggedInUser) {
      return this.get(this.appStore.loggedInUser.id);
    }
    return new UISettings({}, this);
  }

  updateProgress = () => {
    this.updateSongSilder();
    this.showDuration();
  };

  startPlaying = () => {
    var that = this;
    that.wave();
    that.showPlayer = true;
    if (that.downloadedSongs.length >= that.songs.length) {
      return;
    }

    if (that._downloadingNextSongs) {
      return;
    }
    that._downloadingNextSongs = true;

    that.endDownload = false;
    that.wavesurfer.on("play", () => {
      that.isPlaying = true;
    });

    that.wavesurfer.on("pause", () => {
      that.isPlaying = false;
    });

    that.wavesurfer.on("finish", () => {
      that.isPlaying = false;
      if (that.wavesurfer) {
        //if(Math.round(that.wavesurfer.getCurrentTime()) >= Math.floor(that.wavesurfer.getDuration())){
        that.autoNextSong();
        //}
      }
    });

    that.currentSong = that.songs[0];
    this.currentIndice = 1;
    that.fetchSong(that.currentSong).then((buffer) => {
      if (buffer) {
        that.downloadedSongs.push({
          song_id: that.currentSong.id,
          buffer: vdjToMp3Src(buffer),
        });
      }
      if (that.loadCurrentSong()) that.play();
      that.downloadNextSongs(that.currentSongIndex() + 1);
    });
  };

  timeout = (ms, promise) => {
    return new Promise((resolve, reject) => {
      const timeoutId = setTimeout(() => {
        clearTimeout(timeoutId);
        resolve({ statusCode: 504, status: "504" });
      }, ms);
      promise.then(
        (res) => {
          clearTimeout(timeoutId);
          resolve(res);
        },
        (err) => {
          clearTimeout(timeoutId);
          reject(err);
        }
      );
    });
  };

  loadCurrentSong = () => {
    let downloadedSong = this.downloadedSongs.find(
      (s) => s.song_id === this.currentSong.id
    );
    if (downloadedSong) {
      this.wavesurfer.load(downloadedSong.buffer);
      return true;
    } else {
      return false;
    }
  };

  fetchSong = (song) =>
    this.timeout(30000, fetch(this._host + song.song_path, this.myHeaders))
      .then(async (response) => {
        if ((await response.status) === 200) {
          return response.arrayBuffer();
        } else {
          console.log("HTTP error, status = " + response.status);
          return null;
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
        return null;
      });

  reportSongHeard = () => {
    let currentHours = moment().format("HH:mm:ss");
    let diff_s = moment(currentHours, "HH:mm:ss").diff(
      moment(this.initialMoment, "HH:mm:ss"),
      "seconds"
    );
    this.listennigTime = this.listennigTime + diff_s;
    console.log(
      "song_id: ",
      this.currentSong.id,
      "listennigTime: ",
      this.listennigTime
    );
    if (this.listennigTime > 0) {
      let usersListenedSongs = new UsersListenedSongs(
        {
          song_id: this.currentSong.id,
          listening_time: this.listennigTime,
          deleted: 0,
        },
        this.appStore.usersListenedSongs
      );
      usersListenedSongs.save();
    }
  };

  resetInitialMoment = () => {
    this.reportSongHeard();
    this.initialMoment = null;
    this.listennigTime = 0;
  };

  setInitialMoment = () => {
    if (!this.initialMoment) {
      this.initialMoment = moment().format("HH:mm:ss");
    } else {
      if (this.isPlaying) {
        let currentHours = moment().format("HH:mm:ss");
        let diff_s = moment(currentHours, "HH:mm:ss").diff(
          moment(this.initialMoment, "HH:mm:ss"),
          "seconds"
        );
        this.listennigTime = this.listennigTime + diff_s;
      }
      this.initialMoment = moment().format("HH:mm:ss");
    }
  };

  play = () => {
    this.setInitialMoment();
    this.wavesurfer.play();
  };

  pause = () => {
    this.setInitialMoment();
    this.wavesurfer.pause();
  };

  prev = () => {
    var that = this;
    that.resetInitialMoment();
    var currentSongIndex = that.currentSongIndex();
    let wavesurfer = that.wavesurfer;
    const prevSong = that.songs[currentSongIndex - 1];
    if (
      wavesurfer &&
      wavesurfer.getCurrentTime() &&
      prevSong &&
      currentSongIndex > 0 &&
      currentSongIndex <= this.songs.length
    ) {
      this.currentIndice -= 1;
      that.currentSong = prevSong;
      if (that.loadCurrentSong()) that.play();
    }
    //this.scrollView();
  };

  scrollView = () => {
    var elmnt = document.getElementById(this.currentIndice);
    elmnt.scrollIntoView({ block: "center" });
  };

  next = () => {
    var that = this;
    that.resetInitialMoment();
    var currentSongIndex = that.currentSongIndex();
    let wavesurfer = that.wavesurfer;
    const nextSong = that.songs[currentSongIndex + 1];
    if (
      wavesurfer &&
      wavesurfer.getCurrentTime() &&
      nextSong &&
      currentSongIndex >= 0 &&
      currentSongIndex <= that.songs.length
    ) {
      this.currentIndice += 1;
      that.currentSong = nextSong;
      if (that.loadCurrentSong()) that.play();
    }
    //this.scrollView();
  };

  autoNextSong = () => {
    var that = this;
    if (that.wavesurfer.getDuration() !== 0) {
      that.next();
    } else {
      that.play();
    }
  };

  updateSongSilder = () => {
    var that = this;

    if (that.wavesurfer) {
      /*if(Math.round(that.wavesurfer.getCurrentTime()) >= Math.floor(that.wavesurfer.getDuration())){
        that.autoNextSong();
      }*/

      that.currentProgress = Math.floor(that.wavesurfer.getCurrentTime() || 0);
      that.currentProgressTime = that.convertTime(that.currentProgress);
    }
  };

  showDuration = () => {
    var that = this;

    if (that.wavesurfer) {
      this.durationSecs = Math.floor(this.wavesurfer.getDuration() || 0);
      this.currentDuration = this.convertTime(this.durationSecs);
    }
  };

  convertTime = (secs) => {
    var min = Math.floor(secs / 60);
    var sec = secs % 60;
    min = min < 10 ? "0" + min : min;
    sec = sec < 10 ? "0" + sec : sec;
    return min + ":" + sec;
  };

  currentSongIndex = () => {
    if (!this.songs || !this.currentSong) {
      return -1;
    }
    return this.songs.findIndex((s) => s.id === this.currentSong.id);
  };

  downloadNextSongs(songIndex) {
    var that = this;

    if (!songIndex) {
      return;
    }

    if (that.downloadedSongs.length == 0) {
      return;
    }

    if (!that.songs[songIndex]) {
      that.endDownload = true;
      that._downloadingNextSongs = false;
      return;
    }

    if (that.songs.length == 0) {
      return;
    }

    if (that.downloadedSongs.length >= that.songs.length) {
      that._downloadingNextSongs = false;
      return;
    }

    if (that._downloadingNextSongs) {
      if (
        !that.downloadedSongs.find(
          (song) => song.song_id === that.songs[songIndex].id
        )
      ) {
        return that
          .fetchSong(that.songs[songIndex])
          .then((buffer) => {
            if (buffer) {
              that.downloadedSongs.push({
                song_id: that.songs[songIndex].id,
                buffer: vdjToMp3Src(buffer),
              });
            } else {
              console.log("error descarga");
            }
          })
          .then(() => that.downloadNextSongs(songIndex + 1));
      } else {
        that.downloadNextSongs(songIndex + 1);
      }
    }
  }

  signIn = () => {};

  signOut() {
    this.audioDriver.pause();
    this.songs = [];
    this.downloadedSongs = [];
    this.showMainSide = false;
  }
}
