import React, { Component } from "react";
import { observer, PropTypes } from "mobx-react";
import withStore from "../../hocs/withStore";
import {
  Modal,
  ModalHeader,
  ModalContent,
  ModalFooter,
  Text,
  Loader,
  Button,
  Field,
  Columns,
  Column,
  Panel,
} from "ks_storybook";
import { withToastManager } from "react-toast-notifications";
import { EmptyContent } from "../Common";
import moment from "moment";
import { InvoicesItemsAMemberModal } from ".";
import { Screen } from "../Common";

@observer
class InvoicesAMemberModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showInvoicesItemsAMemberModal: false,
      invoicesAMember: null,
      currentInvoicesAMember: null,
    };
  }

  componentDidMount() {
    this.getInvoicesAMemberAll(this.props.account.external_id);
  }

  getInvoicesAMemberAll = (external_id) => {
    this.props.store.kasimuAPIClient
      .search("/users/get_invoices_amember", {
        external_id: external_id,
      })
      .then((res) => {
        if (res.results.ok) this.setState({ invoicesAMember: null });
        else this.setState({ invoicesAMember: res.results });
      });
  };

  handleClose = () => {
    this.props.onClose && this.props.onClose();
  };

  handleInvoicesItemsAMemberModalOpen = (currentInvoicesAMember) => {
    this.setState({
      showInvoicesItemsAMemberModal: true,
      currentInvoicesAMember: currentInvoicesAMember,
    });
  };

  handleInvoicesItemsAMemberModalClose = () => {
    this.setState({
      showInvoicesItemsAMemberModal: false,
      currentInvoicesAMember: null,
    });
  };

  handleInvoicesItemsAMemberModalSave = () => {
    this.reload();
  };

  reload = () => {
    this.setState({
      invoicesAMember: this.getInvoicesAMemberAll(
        this.props.account.external_id
      ),
    });
  };

  getContainerStyle = () => ({
    width: "100%",
    height: "350px",
    overflowY: "auto",
    overflowX: "hidden",
  });

  renderEmptyContent = () => {
    return (
      <React.Fragment>
        <EmptyContent />
      </React.Fragment>
    );
  };

  renderLoader = () => {
    return (
      <Loader
        backgroundColor="transparent"
        icon="sync-alt"
        label={this.props.store.language.label42}
        animation="spin"
      />
    );
  };

  renderHeader = () => {
    const { invoicesAMember } = this.state;
    return !invoicesAMember
      ? this.renderLoader()
      : this.renderInvoices(invoicesAMember);
  };

  renderPanel = (children) => {
    return this.props.store.viewMobile ? (
      children
    ) : (
      <Panel color="gray" className="my-1" padding={"32px 18px"}>
        {children}
      </Panel>
    );
  };
  renderInvoices = (invoices) => {
    return (
      <React.Fragment>
        {this.renderPanel(
          <>
            <Text
              size={this.props.store.viewMobile ? "xxl" : "xl"}
              weight="bold"
              className="mb-1"
            >
              {"Datos en aMember"}
            </Text>
            <div
              className="is-row"
              style={{ flexWrap: "wrap", alignItems: "center" }}
            >
              <Text
                size={this.props.store.viewMobile ? "xl" : "lg"}
                className="mr-1"
                weight="medium"
                upper
              >
                {"Id" + ": "}
              </Text>
              <Text
                size={this.props.store.viewMobile ? "xl" : "lg"}
                color="special"
                multiline
              >
                {invoices.user_id}
              </Text>
            </div>
            <div
              className="is-row"
              style={{ flexWrap: "wrap", alignItems: "center" }}
            >
              <Text
                size={this.props.store.viewMobile ? "xl" : "lg"}
                className="mr-1"
                weight="medium"
                upper
              >
                {"Email" + ": "}
              </Text>
              <Text
                size={this.props.store.viewMobile ? "xl" : "lg"}
                color="special"
                multiline
              >
                {invoices.email}
              </Text>
            </div>
            <div
              className="is-row"
              style={{ flexWrap: "wrap", alignItems: "center" }}
            >
              <Text
                size={this.props.store.viewMobile ? "xl" : "lg"}
                className="mr-1"
                weight="medium"
                upper
              >
                {"CIF" + ": "}
              </Text>
              <Text
                size={this.props.store.viewMobile ? "xl" : "lg"}
                color="special"
                multiline
              >
                {invoices.cif}
              </Text>
            </div>
            <div
              className="is-row"
              style={{ flexWrap: "wrap", alignItems: "center" }}
            >
              <Text
                size={this.props.store.viewMobile ? "xl" : "lg"}
                className="mr-1"
                weight="medium"
                upper
              >
                {"Empresa" + ": "}
              </Text>
              <Text
                size={this.props.store.viewMobile ? "xl" : "lg"}
                color="special"
                multiline
              >
                {invoices.empresa}
              </Text>
            </div>
            <div
              className="is-row"
              style={{ flexWrap: "wrap", alignItems: "center" }}
            >
              <Text
                size={this.props.store.viewMobile ? "xl" : "lg"}
                className="mr-1"
                weight="medium"
                upper
              >
                {"Dirección" + ": "}
              </Text>
              <Text
                size={this.props.store.viewMobile ? "xl" : "lg"}
                color="special"
                multiline
              >
                {invoices.street}
              </Text>
            </div>
          </>
        )}
        <br />
        <div style={this.getContainerStyle()}>
          {invoices.nested.invoices.length > 0
            ? invoices.nested.invoices.map((obj) => (
                <Panel
                  color="gray"
                  padding={
                    this.props.store.viewMobile ? "16px 30px" : "14px 10px"
                  }
                  style={{ borderRadius: !this.props.store.viewMobile && 5 }}
                  className="mb-1"
                >
                  <div
                    className="flex items-center"
                    style={{ justifyContent: "space-between" }}
                  >
                    <div>
                      <Text size="xl" lineBreak>
                        {"#" + obj.invoice_id + "/" + obj.public_id}
                        {", " + obj.paysys_id + " | "}
                        {obj.status === 3 ? (
                          <span className="danger">
                            {"Recurring Cancelled"}
                          </span>
                        ) : (
                          <span className="success">{"Recurring Active"}</span>
                        )}
                        {" | " + obj.currency}
                        {" | tax_rate: " + obj.tax_rate}
                        {" | creado el " +
                          moment(obj.tm_added, "YYYY-MM-DD").format(
                            "DD/MM/YYYY"
                          )}
                        {" | primer pago el " +
                          moment(obj.tm_started, "YYYY-MM-DD").format(
                            "DD/MM/YYYY"
                          )}
                      </Text>

                      <Text
                        size={this.props.store.viewMobile ? "xl" : "lg"}
                        color="special"
                        lineBreak
                      >
                        <span style={{ color: "white" }}>
                          {"PRIMER PRECIO: "}
                        </span>
                        {"first_subtotal: " + obj.first_subtotal}
                        {" | first_discount: " + obj.first_discount}
                        {" | first_tax: " + obj.first_tax}
                        {" | first_total: " + obj.first_total}
                        {" | first_period: " + obj.first_period}
                      </Text>

                      <Text
                        size={this.props.store.viewMobile ? "xl" : "lg"}
                        color="special"
                        lineBreak
                      >
                        <span style={{ color: "white" }}>
                          {"SEGUNDO PRECIO: "}
                        </span>
                        {"second_subtotal: " + obj.second_subtotal}
                        {" | second_discount: " + obj.second_discount}
                        {" | second_tax: " + obj.second_tax}
                        {" | second_total: " + obj.second_total}
                        {" | second_period: " + obj.second_period}
                      </Text>

                      <Text
                        size={this.props.store.viewMobile ? "xl" : "lg"}
                        color="special"
                        lineBreak
                      >
                        {obj.rebill_date ? (
                          <>
                            <span style={{ color: "white" }}>
                              {"Día de próximo cobro: "}
                            </span>
                            {moment(obj.rebill_date, "YYYY-MM-DD").format(
                              "DD/MM/YYYY"
                            )}
                          </>
                        ) : null}
                        {obj.tm_cancelled ? (
                          <>
                            <span style={{ color: "white" }}>
                              {"Cancelado: "}
                            </span>
                            {moment(obj.tm_cancelled, "YYYY-MM-DD").format(
                              "DD/MM/YYYY"
                            )}
                          </>
                        ) : null}
                      </Text>
                      <Text
                        size={this.props.store.viewMobile ? "xl" : "lg"}
                        color="special"
                        lineBreak
                      >
                        <span style={{ color: "white" }}>{"comment: "}</span>
                        {obj.comment}
                        <span style={{ color: "white" }}>{" | "}</span>
                        <span style={{ color: "white" }}>{"note: "}</span>
                        {obj.note}
                      </Text>
                    </div>
                    <div style={{ display: "flex" }}>
                      <Button
                        className="is-pulled-right ml-3"
                        kind="link"
                        size="lg"
                        lead
                        space
                        onClick={() => {
                          this.handleInvoicesItemsAMemberModalOpen(obj);
                        }}
                      >
                        {"invoices-items"}
                      </Button>
                    </div>
                  </div>
                </Panel>
              ))
            : this.renderEmptyContent()}
        </div>
        {
          <Text className="is-pulled-right mt-1" size="xl" lineBreak>
            {"Resultados: " + invoices.nested.invoices.length}
          </Text>
        }
        <br />
      </React.Fragment>
    );
  };

  renderScreen = () => {
    const { account } = this.props;
    return (
      <Screen
        title={
          <div
            className="flex items-center"
            style={{ flexWrap: "wrap", textAlign: "center" }}
          >
            <Text lineBreak size="xxl" weight="bold">
              {"Facturas de aMember"}
            </Text>
          </div>
        }
        onBack={this.handleClose}
      >
        {this.renderHeader()}
      </Screen>
    );
  };

  render() {
    const { currentInvoicesAMember, showInvoicesItemsAMemberModal } =
      this.state;
    const { account } = this.props;
    return (
      <React.Fragment>
        {this.props.store.viewMobile ? (
          <Modal
            show
            onClose={this.handleClose}
            width={this.props.store.viewMobile ? "800px" : "auto"}
            height={this.props.store.viewMobile ? "500px" : "auto"}
          >
            <ModalHeader>
              <div className="flex items-center" style={{ flexWrap: "wrap" }}>
                <Text
                  lineBreak
                  size={this.props.store.viewMobile ? "lg" : "md"}
                  lead
                  weight="black"
                  className="mr-2"
                >
                  {"Facturas"}
                </Text>
                <Text
                  lineBreak
                  size={this.props.store.viewMobile ? "md" : "sm"}
                  lead
                  weight="regular"
                  className="mr-2"
                >
                  {account.username}
                </Text>
              </div>
            </ModalHeader>
            <ModalContent>{this.renderHeader()}</ModalContent>
            <ModalFooter></ModalFooter>
          </Modal>
        ) : (
          this.renderScreen()
        )}
        {showInvoicesItemsAMemberModal && (
          <InvoicesItemsAMemberModal
            currentInvoicesAMember={currentInvoicesAMember}
            onClose={this.handleInvoicesItemsAMemberModalClose}
            onSave={this.reload}
          />
        )}
      </React.Fragment>
    );
  }
}

InvoicesAMemberModal.propTypes = {
  onClose: PropTypes.func,
};

InvoicesAMemberModal.defaultProps = {
  onClose: null,
};

export default withToastManager(withStore(InvoicesAMemberModal));
