import { Store } from "ks_storybook";
import { ProvidersSongs } from "../models";

class ProvidersSongsStore extends Store {
  model = ProvidersSongs;

  fetchAllParams(per_page, orders, search) {
    return this.search(
      { per_page: per_page, orders: JSON.stringify(orders), search: search },
      "providers_get_all_params",
      true,
      "/providers/get_all_params"
    );
  }

  fetchPage(per_page, page, orders, search) {
    return this.search(
      {
        per_page: per_page,
        page: page,
        orders: JSON.stringify(orders),
        search: search,
      },
      "providers_get_all_params_page",
      true,
      "/providers/get_all_params"
    );
  }
}

export default ProvidersSongsStore;
