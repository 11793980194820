import React, { Component } from "react";
import { Text, Columns, Column } from "ks_storybook";
import PropTypes from "prop-types";
import withStore from "../../../hocs/withStore";

class EmptyContent extends Component {
  render() {
    return (
      <Columns>
        <Column
          isSize={{
            mobile: 12,
            tablet: 12,
            desktop: 12,
          }}
          className="has-text-centered"
        >
          <Text
            weigth="strong"
            lead
            size={this.props.store.viewMobile ? "md" : "sm"}
          >
            {this.props.store.language.label89}
          </Text>
        </Column>
      </Columns>
    );
  }
}

EmptyContent.propTypes = {
  message: PropTypes.string,
};

EmptyContent.defaultProps = {
  message: null,
};

export default withStore(EmptyContent);
