import { Store } from "ks_storybook";
import { UsersListenedSongs } from "../models";

class UsersListenedSongsStore extends Store {
  model = UsersListenedSongs;

  fetchAllParams(per_page, filters, orders) {
    return this.search(
      {
        per_page: per_page,
        filters: JSON.stringify(filters),
        orders: JSON.stringify(orders),
      },
      "users_listened_songs",
      true,
      "/users_listened_songs/get_all_params"
    );
  }

  fetchPage(per_page, page, filters, orders) {
    return this.search(
      {
        per_page: per_page,
        page: page,
        filters: JSON.stringify(filters),
        orders: JSON.stringify(orders),
      },
      "users_listened_songs",
      true,
      "/users_listened_songs/get_all_params"
    );
  }

  fetchSearch(per_page, user_id, song_id) {
    return this.search(
      { per_page: per_page, user_id: user_id, song_id: song_id },
      "users_listened_songs_search",
      true,
      "/users_listened_songs/get_search"
    );
  }

  movePage(per_page, page, user_id, song_id) {
    return this.search(
      { per_page: per_page, page: page, user_id: user_id, song_id: song_id },
      "users_listened_songs_search_page",
      true,
      "/users_listened_songs/get_search"
    );
  }
}

export default UsersListenedSongsStore;
