import { Store } from "ks_storybook";
import { VersionUpdated } from "../models";

class VersionUpdatedStore extends Store {
  model = VersionUpdated;

  fetchAll(per_page) {
    return this.search(
      { per_page: per_page },
      "version_updated_all",
      true,
      "/version_updated/get_all"
    );
  }

  movePage(per_page, page) {
    return this.search(
      { per_page: per_page, page: page },
      "version_updated_all_page",
      true,
      "/version_updated/get_all"
    );
  }
}

export default VersionUpdatedStore;
