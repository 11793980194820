import { Store } from "ks_storybook";
import { TranslationCategories } from "../models";

class TranslationCategoriesStore extends Store {
  model = TranslationCategories;

  fetchSearch(per_page, category_id) {
    return this.search(
      { per_page: per_page, category_id: category_id },
      "translation_categories_search",
      true,
      "/translation_categories/get_search"
    );
  }

  movePage(per_page, page, category_id) {
    return this.search(
      { per_page: per_page, page: page, category_id: category_id },
      "translation_categories_search_page",
      true,
      "/translation_categories/get_search"
    );
  }
}

export default TranslationCategoriesStore;
