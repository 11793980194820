import React, { Component } from "react";
import { observer, PropTypes } from "mobx-react";
import withStore from "../../hocs/withStore";
import {
  Text,
  Modal,
  Button,
  ModalHeader,
  ModalContent,
  ModalFooter,
} from "ks_storybook";
import { withToastManager } from "react-toast-notifications";
import { AccountPasswordChange } from ".";

@observer
class AccountManagement extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showPassChange: false,
    };
  }

  handlePassChangeModelOpen = () => {
    this.setState({ showPassChange: true });
  };

  handlePassChangeModelClose = () => {
    this.setState({ showPassChange: false });
  };

  handleClose = () => {
    this.props.onClose && this.props.onClose();
  };

  openLink = (link) => {
    return window.open(link, "_self");
  };

  getLink = () => {
    const user = this.props.store.loggedInUser;
    this.props.store.kasimuAPIClient
      .search("/users/get_autologinlink_amember", {
        external_id: user.external_id,
      })
      .then((res) => {
        if (res.results.ok) {
          this.openLink(res.results.autologinlink);
        }
      });
  };

  render() {
    const { showPassChange } = this.state;
    return (
      <React.Fragment>
        <Modal
          show
          onClose={this.handleClose}
          width={this.props.store.viewMobile ? "500px" : "90%"}
          height={this.props.store.viewMobile ? "220px" : "auto"}
        >
          <ModalHeader>
            <Text
              lineBreak
              size={this.props.store.viewMobile ? "lg" : "md"}
              lead
              weight="strong"
            >
              {"Cuenta"}
            </Text>
          </ModalHeader>
          <ModalContent>
            <Text
              size={this.props.store.viewMobile ? "lg" : "md"}
              multiline
              lineBreak
              color="special"
            >
              {"Gestión de la cuenta"}
            </Text>
            <br />
            <ul>
              <li>
                <Button
                  kind="link"
                  size={this.props.store.viewMobile ? "lg" : "md"}
                  className="is-fullwidth"
                  onClick={this.handlePassChangeModelOpen}
                >
                  {"Cambiar contraseña"}
                </Button>
              </li>
            </ul>
          </ModalContent>
          <ModalFooter></ModalFooter>
        </Modal>
        {showPassChange && (
          <AccountPasswordChange onClose={this.handlePassChangeModelClose} />
        )}
      </React.Fragment>
    );
  }
}

AccountManagement.propTypes = {
  onClose: PropTypes.func,
};

AccountManagement.defaultProps = {
  onClose: null,
};

export default withToastManager(withStore(AccountManagement));
