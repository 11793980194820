import React, { Component } from "react";
import { observer, PropTypes } from "mobx-react";
import withStore from "../../../hocs/withStore";
import {
  Modal,
  Text,
  Button,
  ModalHeader,
  ModalContent,
  ModalFooter,
} from "ks_storybook";
import { withToastManager } from "react-toast-notifications";

@observer
class WarningModal extends Component {
  constructor(props) {
    super(props);
  }

  handleClose = () => {
    this.props.onClose && this.props.onClose();
  };

  handleAccept = () => {
    this.props.onAccept && this.props.onAccept();
  };

  render() {
    const { title, subtitle, btntitle } = this.props;
    return (
      <Modal
        show
        onClose={this.handleClose}
        width={this.props.store.viewMobile ? "640px" : "100%"}
        height={this.props.store.viewMobile ? "240px" : "40%"}
        invert
      >
        <ModalHeader>
          <Text
            upper
            size={this.props.store.viewMobile ? "md" : "sm"}
            lead
            weight="strong"
            lineBreak
          >
            {title}
          </Text>
        </ModalHeader>
        <ModalContent>
          <Text size="xl" lineBreak>
            {subtitle}
          </Text>
        </ModalContent>
        <ModalFooter>
          <nav class="level is-mobile">
            <div class="level-item has-text-centered">
              <Button
                upper
                size={this.props.store.viewMobile ? "lg" : "md"}
                className="px-3"
                onClick={this.handleAccept}
              >
                {btntitle}
              </Button>
            </div>
          </nav>
        </ModalFooter>
      </Modal>
    );
  }
}

WarningModal.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  btntitle: PropTypes.string,
  onAccept: PropTypes.func,
  onClose: PropTypes.func,
};

WarningModal.defaultProps = {
  title: null,
  subtitle: null,
  btntitle: null,
  onAccept: null,
  onClose: null,
};

export default withStore(withToastManager(WarningModal));
