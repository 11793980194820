import { Store } from "ks_storybook";
import { UsersTag } from "../models";

class UsersTagStore extends Store {
  model = UsersTag;

  fetchAllParams(per_page, orders, search) {
    return this.search(
      { per_page: per_page, orders: JSON.stringify(orders), search: search },
      "users_tag_get_all_params",
      true,
      "/users_tag/get_all_params"
    );
  }

  fetchPage(per_page, page, orders, search) {
    return this.search(
      {
        per_page: per_page,
        page: page,
        orders: JSON.stringify(orders),
        search: search,
      },
      "users_tag_get_all_params",
      true,
      "/users_tag/get_all_params"
    );
  }

  fetchAll() {
    return this.search({}, "users_tag_all", true, "/users_tag");
  }

  fetchAllTagsFromOtherUsers() {
    return this.search(
      {},
      "get_tags_from_other_users_all",
      true,
      "/users_tag/get_tags_from_other_users"
    );
  }
}

export default UsersTagStore;
