import { createGlobalStyle } from "styled-components";
import Roboto from "../assets/fonts/Roboto-Regular.ttf";
import RobotoBold from "../assets/fonts/Roboto-Bold.ttf";
import SatoshiRegular from "../assets/fonts/Satoshi/Satoshi-Regular.otf";
import SatoshiMedium from "../assets/fonts/Satoshi/Satoshi-Medium.otf";
import SatoshiBold from "../assets/fonts/Satoshi/Satoshi-Bold.otf";
import SatoshiBlack from "../assets/fonts/Satoshi/Satoshi-Black.otf";

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: Roboto;
    src: url(${Roboto}) format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: Roboto;
    src: url(${RobotoBold}) format("truetype");
    font-weight: bold;
    font-style: bold;
  }

  @font-face {
    font-family: Satoshi;
    font-weight: 400;
    font-style: normal;
    src: url(${SatoshiRegular});
    font-display: block;
  }
  
  @font-face {
    font-family: Satoshi;
    font-weight: 500;
    font-style: normal;
    src: url(${SatoshiMedium});
    font-display: block;
  }
  
  @font-face {
    font-family: Satoshi;
    font-weight: 700;
    font-style: normal;
    src: url(${SatoshiBold});
    font-display: block;
  }
  @font-face {
    font-family: Satoshi;
    font-weight: 900;
    font-style: normal;
    src: url(${SatoshiBlack});
    font-display: block;
  }

  html{
    overflow:hidden;    
  }

  *::selection{
    background-color: #ff3f08;
    color: #fff;
  }

  .ks--layout .ks--layout-side-column{
    width: auto !important;
  }

  .ks--layout .ks--container{
    max-width: 100% !important;
  }

  .dropdown-buttons .ks--panel{
    padding: 4px 0;
    background-color: #FFFFFF;
    border-color: #E7E8EA;
  }
`;

export default GlobalStyle;
