import { Store } from "ks_storybook";
import { User } from "../models";

class UsersStore extends Store {
  model = User;

  fetchAllParams(per_page, filters, orders, search) {
    return this.search(
      {
        per_page: per_page,
        filters: JSON.stringify(filters),
        orders: JSON.stringify(orders),
        search: search,
      },
      "users_get_all_params",
      true,
      "/users/get_all_users_params"
    );
  }

  fetchPage(per_page, page, filters, orders, search) {
    return this.search(
      {
        per_page: per_page,
        page: page,
        filters: JSON.stringify(filters),
        orders: JSON.stringify(orders),
        search: search,
      },
      "users_get_all_params",
      true,
      "/users/get_all_users_params"
    );
  }

  fetchAccountsAll() {
    return this.search(
      { per_page: 10000 },
      "accounts",
      true,
      "/users/accounts"
    );
  }

  fetchInvoicesAMemberAll(external_id) {
    return this.search(
      { external_id: external_id },
      "get_invoices_amember",
      true,
      "/users/get_invoices_amember"
    );
  }

  saveAsAccount(model) {
    var str = model.name;
    var pos = str.indexOf("@");
    if (pos === -1) {
      model.name = `@${model.name}`;
    }

    return this.save(model, "/users/create_account");
  }
}

export default UsersStore;
