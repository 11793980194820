import React, { Component } from "react";
import { observer, PropTypes } from "mobx-react";
import withStore from "../../hocs/withStore";
import {
  Modal,
  ModalHeader,
  ModalContent,
  ModalFooter,
  Text,
  TextInput,
  Field,
  Button,
  Loader,
  Panel,
  Columns,
  Column,
} from "ks_storybook";
import { EmptyContent } from "../Common";
import moment from "moment";
import { withToastManager } from "react-toast-notifications";

@observer
class InvoicesItemsAMemberModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isSaving: false,
      invoicesItemsAMember: null,
      invoicesItemsEdit: null,
      messageError: null,
    };
  }

  newParams = (invoices_items) => {
    const params = {
      invoice_item_id: invoices_items.invoice_item_id,
      qty: invoices_items.qty,
      second_price: invoices_items.second_price,
      second_subtotal: parseFloat(
        invoices_items.qty * invoices_items.second_price
      ).toFixed(2),
      second_discount: invoices_items.second_discount,
      tax_rate: invoices_items.tax_rate,
      second_tax: invoices_items.second_tax,
      second_total: invoices_items.second_total,
    };
    var res = parseFloat(
      params.second_subtotal - params.second_discount
    ).toFixed(2);
    params.second_tax = parseFloat((res * params.tax_rate) / 100).toFixed(2);
    var result = parseFloat(res) + parseFloat(params.second_tax);
    params.second_total = result.toFixed(2);
    return params;
  };

  componentDidMount() {
    this.getInvoicesItemsAMemberAll(
      this.props.currentInvoicesAMember.invoice_id
    );
  }

  getInvoicesItemsAMemberAll = (invoice_id) => {
    this.props.store.kasimuAPIClient
      .search("/users/get_invoices_items_amember", {
        invoice_id: invoice_id,
      })
      .then((res) => {
        if (res.results.ok)
          this.setState({
            invoicesItemsAMember: null,
            invoicesItemsEdit,
            isSaving: false,
          });
        else {
          this.setState({
            invoicesItemsAMember: res.results,
            invoicesItemsEdit: this.newParams(res.results),
            isSaving: false,
          });
        }
      });
  };

  handleChangeFiled = (sender, value, name, validation) => {
    this.state.invoicesItemsEdit[name] = value;
    this.setState({
      invoicesItemsEdit: this.newParams(this.state.invoicesItemsEdit),
      messageError: null,
    });
  };

  getErrorMessager(codError) {
    const messager = {
      1: "Error, algo salió mal al modificar los items de la factura.",
      2: "Error, algo salió mal al modificar la factura.",
    };
    return messager[codError];
  }

  reload = () => {
    this.getInvoicesItemsAMemberAll(
      this.props.currentInvoicesAMember.invoice_id
    );
    this.props.onSave && this.props.onSave();
  };

  handleSave = () => {
    const { toastManager } = this.props;
    const { invoicesItemsEdit } = this.state;
    this.setState({ isSaving: true, messageError: null }, () => {
      this.props.store.kasimuAPIClient
        .putParams("/users/put_invoices_items", invoicesItemsEdit)
        .then(
          (res) => {
            toastManager.add(
              "La factura y sus items se modificaron exitosamente.",
              {
                appearance: "success",
                autoDismiss: true,
              }
            );
            this.reload();
          },
          (error) => {
            let str = error.toString();
            str = str.replace("Error: ", "");
            let mydatas = JSON.parse(str);
            this.setState({
              messageError: this.getErrorMessager(mydatas["codeResponse"]),
            });
            this.setState({ isSaving: false });
          }
        )
        .catch((error) => {
          toastManager.add(
            "Error, algo salió mal al modificar la factura y sus items.",
            {
              appearance: "error",
              autoDismiss: true,
            }
          );
        });
    });
  };

  handleClose = () => {
    this.props.onClose && this.props.onClose();
  };

  renderEmptyContent = () => {
    return (
      <React.Fragment>
        <EmptyContent />
      </React.Fragment>
    );
  };

  renderLoader = () => {
    return (
      <Loader
        backgroundColor="transparent"
        icon="sync-alt"
        label={this.props.store.language.label42}
        animation="spin"
      />
    );
  };

  renderButtons = () => {
    return (
      <nav class="level is-mobile">
        <div className="level-left" />
        <div className="level-right mb-2">
          {this.state.isSaving ? (
            <Button
              className="is-pulled-right px-3"
              size={this.props.store.viewMobile ? "lg" : "md"}
              disabled
              icon="spinner"
              pulse
            >
              {this.props.store.language.label81}
            </Button>
          ) : (
            <Button
              size={this.props.store.viewMobile ? "lg" : "md"}
              //disabled={this.style.description === ""}
              className="is-pulled-right px-3"
              onClick={this.handleSave}
            >
              <strong>{this.props.store.language.label80}</strong>
            </Button>
          )}
        </div>
      </nav>
    );
  };

  renderHeader = () => {
    const { invoicesItemsAMember } = this.state;
    return !invoicesItemsAMember
      ? this.renderLoader()
      : this.renderInvoicesItems(invoicesItemsAMember);
  };

  renderInvoicesItems = (invoices_items) => {
    const { invoicesItemsEdit } = this.state;
    return (
      <React.Fragment>
        <Text
          size={this.props.store.viewMobile ? "lg" : "md"}
          color="special"
          lineBreak
        >
          {invoices_items.item_title + ", " + invoices_items.item_description}
          {"\n"}
          {"Periodo de " +
            invoices_items.second_period +
            " | " +
            invoices_items.currency}
        </Text>

        <Columns className="my-1" isMultiline>
          <Column isSize={{ mobile: 12, tablet: 12, desktop: 6 }}>
            <Text
              size={this.props.store.viewMobile ? "xl" : "lg"}
              className="my-1"
              weight="strong"
              lineBreak
            >
              {"Datos actuales"}
            </Text>
            <Field
              marginH={this.props.store.viewMobile && "4px"}
              label={"qty"}
              size={this.props.store.viewMobile ? "xl" : "lg"}
              noteSize={this.props.store.viewMobile ? "sm" : "xs"}
              weight="medium"
            >
              <Text
                size={this.props.store.viewMobile ? "xl" : "lg"}
                color="special"
                multiline
                className="mx-1 my-1"
              >
                {invoices_items.qty}
              </Text>
            </Field>
            <Field
              marginH={this.props.store.viewMobile && "4px"}
              label={"second_price"}
              size={this.props.store.viewMobile ? "xl" : "lg"}
              noteSize={this.props.store.viewMobile ? "sm" : "xs"}
              weight="medium"
            >
              <Text
                size={this.props.store.viewMobile ? "xl" : "lg"}
                color="special"
                multiline
                className="mx-1 my-1"
              >
                {invoices_items.second_price}
              </Text>
            </Field>
            <Field
              marginH={this.props.store.viewMobile && "4px"}
              label={"second_discount"}
              size={this.props.store.viewMobile ? "xl" : "lg"}
              noteSize={this.props.store.viewMobile ? "sm" : "xs"}
              weight="medium"
            >
              <Text
                size={this.props.store.viewMobile ? "xl" : "lg"}
                color="special"
                multiline
                className="mx-1 my-1"
              >
                {invoices_items.second_discount}
              </Text>
            </Field>
            <Field
              marginH={this.props.store.viewMobile && "4px"}
              label={"tax_rate"}
              size={this.props.store.viewMobile ? "xl" : "lg"}
              noteSize={this.props.store.viewMobile ? "sm" : "xs"}
              weight="medium"
            >
              <Text
                size={this.props.store.viewMobile ? "xl" : "lg"}
                color="special"
                multiline
                className="mx-1 my-1"
              >
                {invoices_items.tax_rate}
              </Text>
            </Field>
            <Field
              marginH={this.props.store.viewMobile && "4px"}
              label={"second_tax"}
              size={this.props.store.viewMobile ? "xl" : "lg"}
              noteSize={this.props.store.viewMobile ? "sm" : "xs"}
              weight="medium"
            >
              <Text
                size={this.props.store.viewMobile ? "xl" : "lg"}
                color="special"
                multiline
                className="mx-1 my-1"
              >
                {invoices_items.second_tax}
              </Text>
            </Field>
            <Field
              marginH={this.props.store.viewMobile && "4px"}
              label={"second_total"}
              size={this.props.store.viewMobile ? "xl" : "lg"}
              noteSize={this.props.store.viewMobile ? "sm" : "xs"}
              weight="medium"
            >
              <Text
                size={this.props.store.viewMobile ? "xl" : "lg"}
                color="special"
                multiline
                className="mx-1 my-1"
              >
                {invoices_items.second_total}
              </Text>
            </Field>
          </Column>
          <Column isSize={{ mobile: 12, tablet: 12, desktop: 6 }}>
            <Text
              size={this.props.store.viewMobile ? "xl" : "lg"}
              className="my-1"
              weight="strong"
              lineBreak
            >
              {"Datos a editar "}
              <small>{"(Se calcula automaticamente)"}</small>
            </Text>
            <Field
              marginH={this.props.store.viewMobile && "4px"}
              label={"qty"}
              size={this.props.store.viewMobile ? "xl" : "lg"}
              noteSize={this.props.store.viewMobile ? "sm" : "xs"}
              weight="medium"
            >
              <TextInput
                backgroundColor="blackDark"
                borderColor="black"
                className="is-fullwidth my-1"
                size={this.props.store.viewMobile ? "xl" : "lg"}
                paddingInput={"20px"}
                type="number"
                name="qty"
                onChange={this.handleChangeFiled}
                minlength={1}
                value={invoicesItemsEdit.qty}
                autocomplete="nope"
              />
            </Field>
            <Field
              marginH={this.props.store.viewMobile && "4px"}
              label={"second_price"}
              size={this.props.store.viewMobile ? "xl" : "lg"}
              noteSize={this.props.store.viewMobile ? "sm" : "xs"}
              weight="medium"
            >
              <TextInput
                backgroundColor="blackDark"
                borderColor="black"
                className="is-fullwidth my-1"
                size={this.props.store.viewMobile ? "xl" : "lg"}
                paddingInput={"20px"}
                type="number"
                step="any"
                name="second_price"
                onChange={this.handleChangeFiled}
                value={invoicesItemsEdit.second_price}
                autocomplete="nope"
              />
            </Field>
            <Field
              marginH={this.props.store.viewMobile && "4px"}
              label={"second_discount"}
              size={this.props.store.viewMobile ? "xl" : "lg"}
              noteSize={this.props.store.viewMobile ? "sm" : "xs"}
              weight="medium"
            >
              <TextInput
                backgroundColor="blackDark"
                borderColor="black"
                className="is-fullwidth my-1"
                size={this.props.store.viewMobile ? "xl" : "lg"}
                paddingInput={"20px"}
                type="number"
                step="any"
                name="second_discount"
                onChange={this.handleChangeFiled}
                value={invoicesItemsEdit.second_discount}
                autocomplete="nope"
              />
            </Field>
            <Field
              marginH={this.props.store.viewMobile && "4px"}
              label={"tax_rate"}
              size={this.props.store.viewMobile ? "xl" : "lg"}
              noteSize={this.props.store.viewMobile ? "sm" : "xs"}
              weight="medium"
            >
              <TextInput
                backgroundColor="blackDark"
                borderColor="black"
                className="is-fullwidth my-1"
                size={this.props.store.viewMobile ? "xl" : "lg"}
                paddingInput={"20px"}
                type="number"
                step="any"
                name="tax_rate"
                onChange={this.handleChangeFiled}
                minlength={1}
                value={invoicesItemsEdit.tax_rate}
                autocomplete="nope"
              />
            </Field>
            <Field
              marginH={this.props.store.viewMobile && "4px"}
              label={"second_tax"}
              size={this.props.store.viewMobile ? "xl" : "lg"}
              noteSize={this.props.store.viewMobile ? "sm" : "xs"}
              weight="medium"
            >
              <TextInput
                backgroundColor="blackDark"
                borderColor="black"
                className="is-fullwidth my-1"
                size={this.props.store.viewMobile ? "xl" : "lg"}
                paddingInput={"20px"}
                type="number"
                step="any"
                name="second_tax"
                onChange={this.handleChangeFiled}
                value={invoicesItemsEdit.second_tax}
                autocomplete="nope"
              />
            </Field>
            <Field
              marginH={this.props.store.viewMobile && "4px"}
              label={"second_total"}
              size={this.props.store.viewMobile ? "xl" : "lg"}
              noteSize={this.props.store.viewMobile ? "sm" : "xs"}
              weight="medium"
            >
              <TextInput
                backgroundColor="blackDark"
                borderColor="black"
                className="is-fullwidth my-1"
                size={this.props.store.viewMobile ? "xl" : "lg"}
                paddingInput={"20px"}
                type="number"
                step="any"
                name="second_total"
                onChange={this.handleChangeFiled}
                value={invoicesItemsEdit.second_total}
                autocomplete="nope"
              />
            </Field>
          </Column>
        </Columns>
      </React.Fragment>
    );
  };

  render() {
    const { currentInvoicesAMember } = this.props;
    return (
      <React.Fragment>
        <Modal
          show
          onClose={this.handleClose}
          width={this.props.store.viewMobile ? "600px" : "90%"}
        >
          <ModalHeader>
            <Text
              lineBreak
              size={this.props.store.viewMobile ? "md" : "sm"}
              lead
              weight="black"
            >
              {"#" +
                currentInvoicesAMember.invoice_id +
                "/" +
                currentInvoicesAMember.public_id}
            </Text>
          </ModalHeader>
          <ModalContent>
            {this.renderHeader()}
            {this.state.messageError && (
              <Panel color="error" className="mt-2" invert>
                <Text
                  size={this.props.store.viewMobile ? "lg" : "md"}
                  multiline
                >
                  {this.state.messageError}
                </Text>
              </Panel>
            )}
          </ModalContent>
          <ModalFooter>{this.renderButtons()}</ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }
}

InvoicesItemsAMemberModal.propTypes = {
  onClose: PropTypes.func,
  onSave: PropTypes.func,
};

InvoicesItemsAMemberModal.defaultProps = {
  onClose: null,
  onSave: null,
};

export default withToastManager(withStore(InvoicesItemsAMemberModal));
