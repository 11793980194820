import React, { Component } from "react";
import { withRouter } from "react-router";
import { Text, SidebarMenu } from "ks_storybook";
import withStore from "../../hocs/withStore";
import logo from "../../assets/images/logo_on_white.png";

class UsersManagerMenu extends Component {
  state = {
    value: "",
    show: true,
  };

  handleChangeShow = (val) => {
    if (this.state.value !== val) this.setState({ value: val });
    else this.setState({ value: "" });
  };

  renderMultiUserMenu = () => {
    const { handleChangeShow } = this;
    const { value } = this.state;
    const path = this.props.match.path;
    const location = this.props.location.pathname;

    return [
      <React.Fragment>
        <div
          className={
            this.props.store.viewMobile
              ? "media-content pb-4 px-2"
              : "media-content pb-3 pt-3 px-2"
          }
        >
          <Text size="md" color="white" weight="strong" lead lineBreak upper>
            {"Gestor \nde\n usuarios"}
          </Text>
        </div>
        <SidebarMenu
          title={this.props.store.language.label17}
          to={`${path}accountmanagerpage`}
          titleClick={() => handleChangeShow("accountmanagerpage")}
          show={value == "accountmanagerpage"}
          iconKasimu="user-solid"
          activation
        ></SidebarMenu>
      </React.Fragment>,
    ];
  };

  render() {
    var menu = [];
    menu.push(this.renderMultiUserMenu());
    return <React.Fragment>{menu}</React.Fragment>;
  }
}

export default withStore(withRouter(UsersManagerMenu));
