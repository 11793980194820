import { Store } from "ks_storybook";
import { ProcessHistory } from "../models";

class ProcessHistoryStore extends Store {
  model = ProcessHistory;

  fetchAll() {
    return this.search(
      { per_page: 1000 },
      "process_history_all",
      true,
      "/process_history/get_all"
    );
  }

  movePage(per_page, page) {
    return this.search(
      { per_page: per_page, page: page },
      "process_history_all_page",
      true,
      "/process_history/get_all"
    );
  }

  fetchLast(type_process) {
    return this.search(
      { per_page: 1, type_process: type_process },
      "get_process_last",
      true,
      "/process_history/get_process"
    );
  }
}

export default ProcessHistoryStore;
