import { Store } from "ks_storybook";
import { AppLanguages } from "../models";

class AppLanguagesStore extends Store {
  model = AppLanguages;

  fetchAll() {
    return this.search(
      { per_page: 1000 },
      "app_languages_all",
      true,
      "/app_languages"
    );
  }
}

export default AppLanguagesStore;
