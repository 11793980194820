import React, { Component } from "react";
import { observer } from "mobx-react";
import PropTypes from "prop-types";
import withStore from "../../../hocs/withStore";
import { Button, Text } from "ks_storybook";
import { withToastManager } from "react-toast-notifications";

@observer
class Screen extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const styleScreen = {
      position: "fixed",
      minHeight: "100vh",
      minHeight: "fill-available",
      zIndex: 20,
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      background: "#000000",
      //   display: "flex",
      //   flexDirection: "column",
    };

    const styleScreenHeader = {
      height: 8 * 9,
      padding: "0 12px",
      display: "flex",
      alignItems: "center",
    };

    const styleScreenContent = {
      padding: "0 12px",
      //   flex: 1,
      //   height: 0,
      overflowY: "auto",
      height: "100%",
    };

    return (
      <div style={styleScreen}>
        <div style={styleScreenHeader}>
          <div className="ml-1" style={{ width: 16 }}>
            <Button
              iconKasimu="arrow-back"
              kind="link"
              color="basic"
              size={this.props.store.viewMobile ? "xl" : "xl"}
              lead
              space
              onClick={this.props.onBack}
            />
          </div>
          <div style={{ display: "flex", flex: 1, justifyContent: "center" }}>
            {this.props.title}
          </div>
          <div style={{ width: 16 }}></div>
        </div>
        <div style={styleScreenContent}>
          <div style={{ paddingBottom: 72 }}>{this.props.children}</div>
        </div>
      </div>
    );
  }
}

Screen.propTypes = {};

Screen.defaultProps = {};

export default withStore(withToastManager(Screen));
